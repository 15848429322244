<template>
    <div class="inner_pages" id="form_index" :style="`--editbar-color: ${formDetails.survey_info ? formDetails.survey_info.accent_color : '#f2a31d'}`">
        <div class="respective_content">
            <div class="result_wpr new" v-if="loader"><quote-loader class="mt-4" /></div>
            <div class="result_wpr new" v-else>
                <div class="letter_head mb-5 mt-4">
                    <div class="comp_name">
                        <h3>{{ user.businessname }}</h3>
                        <address>{{ user.address }}</address>
                    </div>
                    <img class="comp_logo" v-if="user.logo" :src="user.logo ? user.logo : require('@/assets/images/thumb/default-logo.svg')">
                </div>
                <div class="form-response">
                    <form-detail-content :form-details="formDetails" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions } from 'vuex'

const FormDetailContent = defineAsyncComponent(() => import('@/pages/form/components/FormDetailContent'))

export default {
    name: 'form-response',

    data () {
        return  {
            loader: false,
            formDetails: {},
            respondent: {},
            formPdfName: '',
            user: {},
        };
    },

    components: {
        FormDetailContent,
    },

    mounted () {
        const vm = this;

        const params = {
            contact_id: vm.$route.params.contact,
            survey_id: vm.$route.params.form,
            respondent_id: vm.$route.params.respondent,
        };

        vm.handleViewFormResponse(params);
    },

    methods: {
        ...mapActions({
            viewFormPublicResponse: 'formModule/viewFormPublicResponse',
        }),

        handleViewFormResponse (form) {
            const vm = this;

            const formData = {
                contact_id: form.contact_id,
                survey_id: form.survey_id,
                respondent_id: form.respondent_id,
            }

            vm.respondent = form;
            vm.loader = true;

            vm.viewFormPublicResponse(formData).then((result) => {
                try {
                    vm.formDetails = {
                        respondent_info: {
                            name: result.respondent_info.name,
                            email: result.respondent_info.email,
                            number: result.respondent_info.number,
                            full_address: result.respondent_info.full_address,
                            birthdate: result.respondent_info.birthdate,
                        },
                        survey_info: result.survey_info,
                        survey_questions: result.survey_questions,
                        signatures: result.signatures,
                        custom_fields: result.custom_fields,
                        custom_fields_answers: result.custom_fields_answers,
                        contact: result.contact,
                    };

                    vm.user = result.user;
                    vm.loader = false;
                } catch (e) {
                    console.log(e);
                    vm.$router.push({ name: 'Error-404' });
                }
            });
        },
    }
}
</script>

<style scoped>
    .result_wpr.new {
        min-height: 65vh;
    }

    .result_wpr.new .quote_wpr {
        height: 100%;
        justify-content: center;
    }

    :deep(.form-response .info_wpr),
    :deep(.form-response .form_details) {
        background: #FFF;
    }

    :deep(.form-response .form-title) {
        margin-bottom: 15px;
    }
</style>