<template>
    <div class="inner_pages" id="form_index" :style="`--editbar-color: ${formDetails.survey_info ? formDetails.survey_info.accent_color : '#f2a31d'}`">
        <!-- <inner-sidebar /> -->
        <div class="respective_content">
            <div class="acton_header m-0">
                <div class="left_section">
                    <h2>Forms</h2>
                    <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="gererateReport()"><i class="fas fa-download"></i></li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new" v-if="loader"><quote-loader class="mt-4" /></div>
            <div class="result_wpr new" v-else>
                <div class="letter_head mb-5 mt-4">
                    <div class="comp_name">
                        <h3>{{ user.businessname }}</h3>
                        <address>{{ user.address }}</address>
                    </div>
                    <img class="comp_logo" v-if="user.logo" :src="user.logo ? user.logo : require('@/assets/images/thumb/default-logo.svg')">
                </div>
                <div class="form-response mt-4">
                    <form-detail-content :form-details="formDetails" :contact="formDetails.contact" />
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/3fe4468eeec34adfb91620693a80cd45?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'

const FormDetailContent = defineAsyncComponent(() => import('@/pages/form/components/FormDetailContent'))

export default {
    name: 'form-response',

    data () {
        return  {
            tutorial: false,
            loader: false,
            formDetails: {
                contact: {},
            },
            respondent: {},
            formPdfName: '',
        };
    },

    components: {
        FormDetailContent,
    },

    computed: mapState({
        user: state => state.authModule.user,
    }),

    mounted () {
        const vm = this;

        const params = {
            contact_id: vm.$route.params.contact,
            survey_id: vm.$route.params.form,
            respondent_id: vm.$route.params.respondent,
        };

        vm.handleViewFormResponse(params);
    },

    methods: {
        ...mapActions({
            viewFormResponse: 'formModule/viewFormResponse',
            downloadFormResponse: 'formModule/downloadFormResponse',
        }),

        handleViewFormResponse (form) {
            const vm = this;

            const formData = {
                contact_id: form.contact_id,
                survey_id: form.survey_id,
                respondent_id: form.respondent_id,
            }

            vm.respondent = form;
            vm.loader = true;

            vm.viewFormResponse(formData).then((result) => {
                vm.formDetails = {
                    respondent_info: {
                        name: result.respondent_info.name,
                        email: result.respondent_info.email,
                        number: result.respondent_info.number,
                        full_address: result.respondent_info.full_address,
                        birthdate: result.respondent_info.birthdate,
                    },
                    survey_info: result.survey_info,
                    survey_questions: result.survey_questions,
                    signatures: result.signatures,
                    custom_fields: result.custom_fields,
                    custom_fields_answers: result.custom_fields_answers,
                    contact: result.contact,
                };

                vm.formPdfName = (Date.now()+'-'+vm.formDetails.survey_info.survey_name).replaceAll(' ', '-');

                vm.loader = false;
            });
        },

        gererateReport () {
            const vm = this;

            const formData = {
                contact_id: vm.respondent.user_id ? vm.respondent.user_id : vm.$route.params.contact,
                survey_id: vm.respondent.survey_id,
                respondent_id: vm.respondent.respondent_id,
                file_name: vm.formPdfName,
            }

            vm.downloadFormResponse(formData);
        },
    }
}
</script>

<style scoped>
    .result_wpr.new {
        min-height: 65vh;
    }

    .result_wpr.new .quote_wpr {
        height: 100%;
        justify-content: center;
    }

    :deep(.form-response .info_wpr),
    :deep(.form-response .form_details) {
        background: #FFF;
    }

    :deep(.form-response .form-title) {
        margin-bottom: 15px;
    }
</style>