<template>
    <div class="preview_area show_sidebar">
        <div class="loader_area" v-show="formDetailLoader"><quote-loader/></div>
        <div v-show="!formDetailLoader">
            <div class="global_setting" :style="`--var-accent-color: ${form.accent_color != null ? form.accent_color : '#F2A31D'}; --editbar-color: ${form.accent_color}`">
                <router-link  :to="{ name: 'FormIndex'}" class="item_ttl">
                    <button class="close_btn modal-window-close-button">
                        <i class="fas fa-long-arrow-alt-left"></i>
                    </button>
                </router-link>
                <div class="tabs_content">
                    <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmitV2" v-slot="{ errors }" ref="forms-setting-form" class="forms-setting-form">
                        <div class="content_area">
                            <div class="info_bar">
                                <!-- <div class="time">Estimated Time: <span>6 min.</span></div> -->
                                <div class="step_bar2">
                                    <ul>
                                        <li :class="{ active : step == 0 || step > 0 }">
                                            <span @click="step = 0">1</span>
                                            <h6>General</h6>
                                        </li>
                                        <li :class="{ active : step == 1 || step > 1 }">
                                            <span @click="step = 1">2</span>
                                            <h6>Questions</h6>
                                        </li>
                                        <li :class="{ active : step == 2 || step > 2 }">
                                            <span @click="step = 2">3</span>
                                            <h6>Opt-In</h6>
                                        </li>
                                        <li :class="{ active : step == 3 || step > 3 }">
                                            <span @click="step = 3">4</span>
                                            <h6>Thank You</h6>
                                        </li>
                                        <li :class="{ active : step == 4 || step > 4 }">
                                            <span @click="step = 4">5</span>
                                            <h6>Notifications</h6>
                                        </li>
                                        <li :class="{ active : step == 5 || step > 5 }">
                                            <span @click="step = 5">6</span>
                                            <h6>SEO</h6>
                                        </li>
                                    </ul>
                                    <div class="total_bar"><span :style="`width:${(100 / 5) * step }%`"></span></div>
                                </div>
                            </div>
                            <ul class="btn_list" v-if="step == 0">
                                <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                                <li v-else @click="closeAllSection()">Close All</li>
                            </ul>
                            <div class="content_wpr" v-show="step == 0" data-step="0">
                                <div class="section_content w-100">
                                    <div class="section_header">
                                        <h2>General</h2>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="setting_wpr">
                                                <div class="form_grp p-0 mt-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Form Name</label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.survey_name }">
                                                            <Field autocomplete="off" type="text" name="survey_name" v-model="form.survey_name" placeholder="ex: New Form" rules="required" label="form name" />
                                                        </div>
                                                        <ErrorMessage name="survey_name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp p-0 mt-4">
                                                    <div class="group_item">
                                                        <label class="input_label">Form Description</label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.form_description }">
                                                            <Field autocomplete="off" name="form_description" v-model="form.form_description" rules="required">
                                                                <textarea name="form_description" id="form_description" v-model="form.form_description" cols="10" rows="10" placeholder="Description Goes Here.."></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="form_description" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="capsule_elm pt-2">
                                                    <h5>Apply Saved Styling?</h5>
                                                    <label for="apply_saved_styling" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="apply_saved_styling" :true-value="1" :false-value="0" v-model="form.apply_saved_styling" @change="handleApplySavedStylingWarning()" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="color_container mt-2">
                                            <div class="setting_wpr">
                                                <h3 class="sub_header m-0">
                                                    Start Screen?
                                                    <label for="show_form_vid" class="switch_option capsule_btn border-0">
                                                        <input type="checkbox" id="show_form_vid" :true-value="1" :false-value="0" v-model="form.display_instructions" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </h3>
                                                <div class="mt-2" v-if="form.display_instructions">
                                                    <div class="grey_box">
                                                        <div class="capsule_elm">
                                                            <h5>Video Link</h5>
                                                            <label for="video_url" class="switch_option capsule_btn">
                                                                <input type="radio" id="video_url" name="instruction_type" :value="2" v-model="form.instruction_settings.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr" v-if="form.instruction_settings.option == 2">
                                                            <div class="form_grp pt-1">
                                                                <div class="group_item">
                                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.instruction_settings_url }">
                                                                        <Field autocomplete="off" type="text" name="instruction_settings_url" placeholder="" v-model="form.instruction_settings.url" rules="url" label="video url" />
                                                                        <span class="prefix">URL</span>
                                                                    </div>
                                                                    <ErrorMessage name="instruction_settings_url" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Embeded Video</h5>
                                                            <label for="embed_video" class="switch_option capsule_btn">
                                                                <input type="radio" id="embed_video" name="instruction_type" :value="1" v-model="form.instruction_settings.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr" v-if="form.instruction_settings.option == 1">
                                                            <div class="form_grp pt-1">
                                                                <div class="group_item">
                                                                    <div class="field_wpr" :class="{ 'has-error': errors.instruction_settings_embed }">
                                                                        <Field autocomplete="off" type="textarea" name="instruction_settings_embed" v-model="form.instruction_settings.embed">
                                                                            <textarea cols="10" rows="10" placeholder="Embeded Code Goes Here..." v-model="form.instruction_settings.embed"></textarea>
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage name="instruction_settings_embed" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Image</h5>
                                                            <label for="add_image" class="switch_option capsule_btn border-0">
                                                                <input type="radio" id="add_image" name="instruction_type" :value="3" v-model="form.instruction_settings.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="upload_image mt-1" v-if="form.instruction_settings.option == 3">
                                                            <image-library v-model="form.instruction_settings.file" image-type="offer" upload-text="Image" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mt-4">
                                                        <div class="group_item">
                                                            <label class="input_label">Button Text <a class="reset-default" @click="form.instruction_settings.label = defaultForm.instruction_settings.label">Reset to default</a></label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.instruction_settings_label }">
                                                                <Field autocomplete="off" type="text" name="instruction_settings_label" placeholder="ex: Smart Step" v-model="form.instruction_settings.label" />
                                                            </div>
                                                            <ErrorMessage name="instruction_settings_label" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Button Color <a class="reset-default" @click="form.instruction_settings.btncolor = defaultForm.instruction_settings.btncolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="instruction_settings_button_color" v-model="form.instruction_settings.btncolor" type="text" label="button color">
                                                                <color-picker v-model="form.instruction_settings.btncolor" :classes="{ 'has-error': errors.instruction_settings_button_color }" />
                                                            </Field>
                                                            <ErrorMessage name="instruction_settings_button_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Button Text Color <a class="reset-default" @click="form.instruction_settings.btntextcolor = defaultForm.instruction_settings.btntextcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="instruction_settings_button_text_color" v-model="form.instruction_settings.btntextcolor" type="text" label="button color">
                                                                <color-picker v-model="form.instruction_settings.btntextcolor" :classes="{ 'has-error': errors.instruction_settings_button_text_color }" />
                                                            </Field>
                                                            <ErrorMessage name="instruction_settings_button_text_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="color_container mt-2">
                                            <div class="setting_wpr">
                                                <h3 class="sub_header mt-2">Question flow</h3>
                                                <div class="grey_box mb-4">
                                                    <div class="capsule_elm">
                                                        <h5>Multi Step</h5>
                                                        <label for="multi" class="switch_option capsule_btn">
                                                            <input type="radio" id="multi" :value="1" v-model="form.question_view" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Long Form</h5>
                                                        <label for="long" class="switch_option capsule_btn">
                                                            <input type="radio" id="long" :value="2" v-model="form.question_view" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm mb-2 pb-2 border-bottom">
                                                    <h5>Show Question Numbers</h5>
                                                    <label for="question_number" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="question_number" :true-value="1" :false-value="0" v-model="form.show_question_number" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="capsule_elm pb-2 mb-2 border-bottom">
                                                    <h5>Auto Advance</h5>
                                                    <label for="advance_form" class="switch_option capsule_btn" v-if="form.question_view == 1">
                                                        <input type="checkbox" id="advance_form" :true-value="1" :false-value="0" v-model="form.auto_advance" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Make Private</h5>
                                                    <label for="private-page" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="private-page" :true-value="1" :false-value="0" v-model="form.is_private" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100">
                                    <div class="section_header">
                                        <h2>Color</h2>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="setting_wpr">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Accent Color <a class="reset-default" @click="form.accent_color = defaultForm.accent_color">Reset to default</a></label>
                                                        <color-picker v-model="form.accent_color" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">IP Background Color <a class="reset-default" @click="form.ip_bgcolor = defaultForm.ip_bgcolor">Reset to default</a></label>
                                                        <color-picker v-model="form.ip_bgcolor" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">IP Text Color <a class="reset-default" @click="form.ip_textcolor = defaultForm.ip_textcolor">Reset to default</a></label>
                                                        <color-picker v-model="form.ip_textcolor" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100" @click="goTo('hdrsection')" ref="hdrsetting">
                                    <div class="section_header">
                                        <h2>Header</h2>
                                        <span class="status" :style="`color: ${form.display_logo ? '#2f7eed' : '#999'};`">{{ form.display_logo ? 'On' : 'Off'}}</span>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="capsule_elm">
                                                <h5>Show</h5>
                                                <label for="display-header" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="display-header" :true-value="1" :false-value="0" v-model="form.display_logo" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div class="setting_wpr" v-if="form.display_logo">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Company Name</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                                <Field autocomplete="off" type="text" name="company_branding" placeholder="ex: Company Name" v-model="form.company_branding" />
                                                            </div>
                                                            <ErrorMessage name="company_branding" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Header Background Color <a class="reset-default" @click="form.header_bgcolor = defaultForm.header_bgcolor">Reset to default</a></label>
                                                            <color-picker v-model="form.header_bgcolor" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Header Text Color <a class="reset-default" @click="form.header_textcolor = defaultForm.header_textcolor">Reset to default</a></label>
                                                            <color-picker v-model="form.header_textcolor" />
                                                        </div>
                                                    </div>
                                                    <h3 class="sub_header">Header Logo</h3>
                                                    <image-library v-model="form.logo" image-type="public-logo" upload-text="Logo" />
                                                    <h3 class="sub_header mt-5">Header Author Image</h3>
                                                    <image-library v-model="form.author" image-type="author-image" upload-text="Author Image" />
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100" @click="goTo('cvrsection')" ref="cvrsetting">
                                    <div class="section_header">
                                        <h2>Cover</h2>
                                        <span class="status" :style="`color: ${form.display_hero ? '#2f7eed' : '#999'};`">{{ form.display_hero ? 'On' : 'Off'}}</span>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="capsule_elm">
                                                <h5>Show <span v-if="!form.small_cover">Upload a small screen cover for a better view</span></h5>
                                                <label for="display-cover" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="display-cover" :true-value="1" :false-value="0" v-model="form.display_hero" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div v-if="form.display_hero">
                                                    <div class="cover_type">
                                                        <ul class="type_list">
                                                            <li>
                                                                <label for="clr_text">
                                                                    <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                                                    <img src="@/assets/images/background.svg" alt="">
                                                                    <h5>Color + Text</h5>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label for="img_text">
                                                                    <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                                                    <img src="@/assets/images/picture.svg" alt="">
                                                                    <h5>Image + Text</h5>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label for="img_only">
                                                                    <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                                                    <img src="@/assets/images/picture.svg" alt="">
                                                                    <h5>Image Only</h5>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.cover_type !== 3 && form.display_hero">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Headline
                                                                    <div class="flex-center">
                                                                        <button type="button" class="reset-default" @click="form.other_header_info = defaultForm.other_header_info">Reset to default</button>
                                                                        <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                    </div>
                                                                </label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.other_header_info }">
                                                                    <Field autocomplete="nofill" type="text" name="other_header_info" v-model="form.other_header_info" placeholder="Compelling Headline Goes Here" />
                                                                </div>
                                                                <ErrorMessage name="other_header_info" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div v-if="headlineSetting">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Family <a class="reset-default" @click="form.headline_setting.font_family = defaultForm.headline_setting.font_family">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Weight <a class="reset-default" @click="form.headline_setting.font_weight = defaultForm.headline_setting.font_weight">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Size <a class="reset-default" @click="form.headline_setting.font_size = defaultForm.headline_setting.font_size">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Color <a class="reset-default" @click="form.cover_textcolor = defaultForm.cover_textcolor">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="headline_setting_cover_textcolor" v-model="form.cover_textcolor" type="text" label="element color">
                                                                        <color-picker v-model="form.cover_textcolor" :classes="{ 'has-error': errors.headline_setting_cover_textcolor }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_cover_textcolor" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="mt-4">
                                                                <div class="capsule_elm">
                                                                    <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                    <label for="headline_shadow" class="switch_option capsule_btn">
                                                                        <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                                <div v-if="form.headline_setting.has_shadow">
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-x <a class="reset-default" @click="form.headline_setting.shadow_x = defaultForm.headline_setting.shadow_x">Reset to default</a></label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-y <a class="reset-default" @click="form.headline_setting.shadow_y = defaultForm.headline_setting.shadow_y">Reset to default</a></label>
                                                                            <div class="field_wpr ">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Blur-Radius <a class="reset-default" @click="form.headline_setting.shadow_blur = defaultForm.headline_setting.shadow_blur">Reset to default</a></label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Shadow Color <a class="reset-default" @click="form.headline_setting.shadow_color = defaultForm.headline_setting.shadow_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                                <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                            </Field>
                                                                            <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Sub Headline
                                                                    <div class="flex-center">
                                                                        <button type="button" class="reset-default" @click="form.description = defaultForm.description">Reset to default</button>
                                                                        <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                    </div>
                                                                </label>
                                                                <div class="field_wpr">
                                                                    <Field autocomplete="nofill" type="text" name="description" v-model="form.description" placeholder="Enter text here" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="subHeadlineSetting">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Family <a class="reset-default" @click="form.subheadline_setting.font_family = defaultForm.subheadline_setting.font_family">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Weight <a class="reset-default" @click="form.subheadline_setting.font_weight = defaultForm.subheadline_setting.font_weight">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Size <a class="reset-default" @click="form.subheadline_setting.font_size = defaultForm.subheadline_setting.font_size">Reset to default</a></label>
                                                                    <div class="field_wpr m-0">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultForm.subheadline_setting.font_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                        <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="mt-4">
                                                                <div class="capsule_elm">
                                                                    <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                    <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                        <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                                <div v-if="form.subheadline_setting.has_shadow">
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-x <a class="reset-default" @click="form.subheadline_setting.shadow_x = defaultForm.subheadline_setting.shadow_x">Reset to default</a></label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-y <a class="reset-default" @click="form.subheadline_setting.shadow_y = defaultForm.subheadline_setting.shadow_y">Reset to default</a></label>
                                                                            <div class="field_wpr ">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Blur-Radius <a class="reset-default" @click="form.subheadline_setting.shadow_blur = defaultForm.subheadline_setting.shadow_blur">Reset to default</a></label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultForm.subheadline_setting.shadow_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                                <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                            </Field>
                                                                            <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="setting_wpr mt-5" v-if="form.cover_type !== 1 && form.display_hero">
                                                        <h3 class="sub_header">Cover Image</h3>
                                                        <image-library v-model="form.cover" image-type="public-cover" upload-text="Cover" />
                                                        <div class="border-bottom pb-2 mb-2">
                                                            <div class="capsule_elm">
                                                                <h5>Small Screen Image</h5>
                                                                <label for="small_image" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <image-library v-if="form.has_small_screen_cover" v-model="form.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                                        </div>
                                                        <div class="capsule_elm">
                                                            <h5>Add Image Overlay</h5>
                                                            <label for="overlay" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.has_cover_overlay">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Overlay Color <a class="reset-default" @click="form.overlay_color = defaultForm.overlay_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                                                        <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="overlay_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Overlay Opacity <a class="reset-default" @click="form.overlay_opacity = defaultForm.overlay_opacity">Reset to default</a></label>
                                                                    <div class="field_wpr">
                                                                        <Field autocomplete="nofill" name="overlay_opacity" v-model="form.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                                                    </div>
                                                                    <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="setting_wpr mt-5" v-if="form.cover_type === 1 && form.display_hero">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Cover Background <a class="reset-default" @click="form.cover_background_color = defaultForm.cover_background_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                                                    <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                                </Field>
                                                                <ErrorMessage name="cover_background_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100" v-if="form.question_view == 1">
                                    <div class="section_header">
                                        <h2>Progress Bar</h2>
                                        <span class="status" :style="`color: ${form.display_progressbar ? '#2f7eed' : '#999'};`">{{ form.display_progressbar ? 'On' : 'Off'}}</span>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="capsule_elm">
                                                <h5>Show</h5>
                                                <label for="progressbar-status" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="progressbar-status" v-model="form.display_progressbar" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div class="setting_wpr" v-if="form.display_progressbar">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Progress Bar Color   <a class="reset-default" @click="form.progressbar_color = defaultForm.progressbar_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="progressbar_color" v-model="form.progressbar_color" type="text" label="button color">
                                                                <color-picker v-model="form.progressbar_color" :classes="{ 'has-error': errors.progressbar_color }" />
                                                            </Field>
                                                            <ErrorMessage name="progressbar_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100">
                                    <div class="section_header">
                                        <h2>Coach</h2>
                                        <span class="status" :style="`color: ${form.display_coach ? '#2f7eed' : '#999'};`">{{ form.display_coach ? 'On' : 'Off'}}</span>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="capsule_elm">
                                                <h5>Show</h5>
                                                <label for="coachcard-2" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="coachcard-2" :true-value="1" :false-value="0" v-model="form.display_coach" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div class="setting_wpr" v-if="form.display_coach">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Coach Headline <a class="reset-default" @click="form.coach_headline = defaultForm.coach_headline">Reset to default</a></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.coach_headline }">
                                                                <Field autocomplete="nofill" type="text" name="coach_headline" v-model="form.coach_headline" placeholder="ex: Fitness Training Course" />
                                                            </div>
                                                            <ErrorMessage name="coach_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Coach Name <a class="reset-default" @click="form.coach_name = defaultForm.coach_name">Reset to default</a></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.coach_name }">
                                                                <Field autocomplete="nofill" type="text" name="coach_name" v-model="form.coach_name" placeholder="Joe Trainer" />
                                                            </div>
                                                            <ErrorMessage name="coach_name" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Coach Title <a class="reset-default" @click="form.coach_title = defaultForm.coach_title">Reset to default</a></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.coach_title }">
                                                                <Field autocomplete="nofill" type="text" name="coach_title" v-model="form.coach_title" placeholder="PHD, CSCS" />
                                                            </div>
                                                            <ErrorMessage name="coach_title" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <coach-bio-component v-model="form.coach_bio" :default-value="defaultForm.coach_bio" :reset-to-default-watcher="resetToDefaultWatcher" :errors="errors" />
                                                    </div>
                                                    <h3 class="sub_header">Coach Image</h3>
                                                    <image-library v-model="form.coach_image" image-type="coach-logo" :is-avatar="true" />
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100" @click="goTo('ftrsection')" ref="ftrsetting">
                                    <div class="section_header">
                                        <h2 class="sub_header m-0">
                                            Footer
                                        </h2>
                                        <span class="status" :style="`color: ${form.display_footer ? '#2f7eed' : '#999'};`">{{ form.display_footer ? 'On' : 'Off'}}</span>
                                        <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                    </div>
                                    <div class="toggle_content">
                                        <div class="color_container mt-1">
                                            <div class="capsule_elm">
                                                <h5>Show</h5>
                                                <label for="form-footer" class="switch_option capsule_btn"  @click="goTo('ftrsection')" ref="ftrsetting">
                                                    <input type="checkbox" id="form-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <p class="saved-style-text" @click="handleApplySavedStyle('footer')">Apply saved footer styling</p>
                                            <Transition duration="550" name="nested">
                                                <div class="mt-4" v-if="form.display_footer">
                                                    <div class="capsule_elm">
                                                        <h5>Facebook</h5>
                                                        <label for="facebook" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.has_facebook">
                                                        <div class="form_grp p-0 mb-4">
                                                            <div class="group_item">
                                                                <div class="field_wpr" :class="{ 'has-error': errors.footer_facebook }">
                                                                    <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                                </div>
                                                                <ErrorMessage name="footer_facebook" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Twitter</h5>
                                                        <label for="twitter" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.has_twitter">
                                                        <div class="form_grp p-0 mb-4">
                                                            <div class="group_item">
                                                                <div class="field_wpr" :class="{ 'has-error': errors.footer_twitter }">
                                                                    <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                                </div>
                                                                <ErrorMessage name="footer_twitter" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Instagram</h5>
                                                        <label for="instagram" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.has_instagram">
                                                        <div class="form_grp p-0 mb-4">
                                                            <div class="group_item">
                                                                <div class="field_wpr" :class="{ 'has-error': errors.footer_instagram }">
                                                                    <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                                </div>
                                                                <ErrorMessage name="footer_instagram" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Linkedin</h5>
                                                        <label for="linkedin" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.has_linkedin" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr pb-3" v-if="form.has_linkedin">
                                                        <div class="form_grp p-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_linkedin }">
                                                                    <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                                </div>
                                                                <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Youtube</h5>
                                                        <label for="youtube" class="switch_option capsule_btn border-0">
                                                            <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.has_youtube" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr pb-3" v-if="form.has_youtube">
                                                        <div class="form_grp p-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_youtube }">
                                                                    <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                                </div>
                                                                <ErrorMessage name="footer_youtube" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="setting_wpr">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Icons Color <a class="reset-default" @click="form.footer_textcolor = defaultForm.footer_textcolor">Reset to default</a></label>
                                                                <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                                                    <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Background Color <a class="reset-default" @click="form.footer_bgcolor = defaultForm.footer_bgcolor">Reset to default</a></label>
                                                                <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                                                    <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Copyright Text</label>
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_company }">
                                                                    <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" placeholder="© 2022 - Superfit Coaching" />
                                                                </div>
                                                                <ErrorMessage name="footer_company" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Terms Link</label>
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_terms }">
                                                                    <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage name="footer_terms" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Privacy Link</label>
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_policy }">
                                                                    <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage name="footer_policy" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content_wpr" v-show="step == 1" data-step="1">
                                <div class="section_content w-100" @click="goTo('qstsection')" ref="qstsetting">
                                    <div class="section_header">
                                        <h2>Questions Settings</h2>
                                    </div>
                                    <div class="quest_headline">
                                        <h3 class="sub_header mb-0">
                                            Questions Headline
                                            <label for="show_quest_headline" class="switch_option capsule_btn border-0 p-0">
                                                <input type="checkbox" id="show_quest_headline" :true-value="1" :false-value="0" v-model="form.display_question_headline" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </h3>
                                        <div class="setting_wpr">
                                            <div class="form_grp" v-if="form.display_question_headline">
                                                <div class="group_item">
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.question_headline }">
                                                        <Field autocomplete="off" type="text" name="question_headline" v-model="form.question_headline" placeholder="ex: Question Headline" />
                                                    </div>
                                                    <ErrorMessage name="question_headline" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <draggable v-model="questions" item-key="id" class="faq_list mt-3" :animation="200" @end="handleQuestionSort" handle=".handle-cat-drag">
                                        <template #item="{ element, index }">
                                            <li class="move" :key="index" :id="`question-accordion-${index}`" :class="{ 'open': index == questionIndex }">
                                                <h5 class="category_bar">
                                                    <div class="bar_text">
                                                        <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                                        <div class="quest_box" v-if="element.input_type_id != 7">
                                                            <div class="redactor-styles redactor-in p-0" v-html="element.question ? element.question : 'New Question'"></div>
                                                            <div class="sub_text" v-if="element.sub_text">{{ element.sub_text }}</div>
                                                        </div>
                                                        <div class="quest_box" v-if="element.input_type_id == 7">
                                                            <div class="redactor-styles redactor-in p-0" v-html="element.question ? element.question : 'New Question'"></div>
                                                            <div class="sub_text" v-if="element.sub_text">{{ element.sub_text }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="bar_actions">
                                                        <!-- <button type="button" class="edit pointer"><i class="far fa-edit mr-1"></i>Edit</button> -->
                                                        <label class="pointer has" @click="toggleQuestion($event, index)">
                                                            {{index == questionIndex ? 'Close' : 'Open'}} 
                                                        </label>
                                                        <button type="button" class="delete_btn" @click="handleDeleteQuestion(element)"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </h5>
                                                <div class="quest_setup">
                                                    <widget-question-form :question="element" :ref="`question-form-${index}`" />
                                                </div>
                                            </li>
                                        </template>
                                    </draggable>
                                    <button type="button" class="add_btn ml-auto mr-0 mt-2" @click="addNewQuestion() "><i class="fas fa-plus"></i>Add New Question</button>
                                </div>
                            </div>
                            <div class="content_wpr" v-show="step == 2" data-step="2">
                                <div class="section_content w-100 open_area" @click="goTo('optsection')" ref="optsetting">
                                    <div class="section_header">
                                        <h2>Opt-in Settings</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h3 class="sub_header m-0">Opt-in?</h3>
                                            <label for="optin" class="switch_option capsule_btn m-0">
                                                <input type="checkbox" id="optin" :true-value="1" :false-value="0" v-model="form.opt_in" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="setting_wpr" v-if="!form.opt_in">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Optin Thank You Message</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.optin_thankyou_message }">
                                                        <Field autocomplete="off" type="textarea" name="optin_thankyou_message" v-model="form.optin_thankyou_message">
                                                            <textarea cols="10" rows="10" placeholder="Thankyou Message Goes Here..." v-model="form.optin_thankyou_message"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="optin_thankyou_message" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.opt_in" class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Opt-in Headline <a class="reset-default" @click="form.optin_headline = defaultForm.optin_headline">Reset to default</a></label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.optin_headline }">
                                                        <Field autocomplete="off" type="text" name="optin_headline" placeholder="ex: Headline Text" v-model="form.optin_headline" />
                                                    </div>
                                                    <ErrorMessage name="optin_headline" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Opt-in Video</h5>
                                                <label for="optin_video" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="optin_video" :true-value="1" :false-value="0" v-model="form.has_optin_video" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="edit_section border py-4 mb-3" v-if="form.has_optin_video">
                                                <div class="border-bottom pb-2">
                                                    <div class="capsule_elm">
                                                        <h5>Video Link</h5>
                                                        <label for="optin_video_url" class="switch_option capsule_btn">
                                                            <input type="radio" id="optin_video_url" :value="2" v-model="form.optin_video_type" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr mb-2" v-if="form.optin_video_type === 2">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.optin_video_url }">
                                                                    <Field autocomplete="off" type="text" name="optin_video_url" placeholder="Video Link Goes Here..." v-model="form.optin_video_url" rules="url" label="optin video url" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <ErrorMessage name="optin_video_url" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="border-bottom py-2">
                                                    <div class="capsule_elm">
                                                        <h5>Embeded Video</h5>
                                                        <label for="optin_embed_video" class="switch_option capsule_btn">
                                                            <input type="radio" id="optin_embed_video" :value="1" v-model="form.optin_video_type" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.optin_video_type === 1">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr" :class="{ 'has-error': errors.optin_video_embed }">
                                                                    <Field autocomplete="off" type="textarea" name="optin_video_embed" v-model="form.optin_video_embed">
                                                                        <textarea cols="10" rows="10" placeholder="Embeded Code Goes Here..." v-model="form.optin_video_embed"></textarea>
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="optin_video_embed" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pt-2">
                                                    <div class="capsule_elm">
                                                        <h5>Image</h5>
                                                        <label for="optin_add_image" class="switch_option capsule_btn">
                                                            <input type="radio" id="optin_add_image" :value="3" v-model="form.optin_video_type" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="upload_image mt-3" v-if="form.optin_video_type === 3">
                                                        <image-library v-model="form.optin_file" image-type="offer" upload-text="Image" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Submit with Member ID</h5>
                                                <label for="sumit_memID" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="sumit_memID" :true-value="1" :false-value="0" v-model="form.submit_with_member_id" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="mb-5" v-if="!form.submit_with_member_id">
                                                <h3 class="sub_header py-2 mt-2 mb-0">Additional Fields <a class="reset-default" @click="form.add_ons = defaultForm.add_ons">Reset to default</a></h3>
                                                <ul class="optin_list">
                                                    <li v-for="field in optinFields" :key="field.value">
                                                        <div class="field_box">
                                                            <label :for="`check-${field.value}`" class="checkbox">
                                                                <input type="checkbox" :id="`check-${field.value}`" :value="field.value" v-model="form.add_ons" hidden>
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>{{ field.title }}</p>
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li v-for="(customField, c) of customFields" :key="c">
                                                        <div class="field_box">
                                                            <label :for="`check-custom-${customField.id}`" class="checkbox">
                                                                <input type="checkbox" :id="`check-custom-${customField.id}`" :value="customField.name" v-model="form.add_ons" hidden>
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>{{ customField.name }}</p>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h3 class="sub_header mt-4">Opt-in Type</h3>
                                            <div class="grey_box">
                                                <div class="capsule_elm">
                                                    <h5>Leads</h5>
                                                    <label for="leads" class="switch_option capsule_btn">
                                                        <input type="radio" id="leads" value="Prospect" v-model="form.type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Staff</h5>
                                                    <label for="staff" class="switch_option capsule_btn">
                                                        <input type="radio" id="staff" value="ActiveEmployee" v-model="form.type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="mt-4" @click="goTo('signature-required-section', 102)" ref="optin-signature-section">
                                                <div class="capsule_elm">
                                                    <h5>Signature Required</h5>
                                                    <label for="sign_required" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="sign_required" :true-value="1" :false-value="0" v-model="form.is_signature" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="editorWpr mt-1 mb-4" v-show="form.is_signature">
                                                    <redactor v-model="form.signature" name="signature" :default-value="selectedForm.signature" :reset-watcher="signatureResetWatcher" />
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Store data to profile</h5>
                                                <label for="store_profile_data" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="store_profile_data" true-value="Yes" :false-value="null" v-model="form.store_copy" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Include Tagging</h5>
                                                <label for="include_tag" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="include_tag" :true-value="1" :false-value="0" v-model="form.has_optin_tagging" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div v-if="form.has_optin_tagging">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">
                                                            Select Tags
                                                            <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                                        </label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                                            <Field autocomplete="off" name="tag" v-model="form.tags">
                                                                <multiselect
                                                                    v-model="form.tags"
                                                                    mode="tags"
                                                                    label="name"
                                                                    value-prop="id"
                                                                    :searchable="true"
                                                                    :group-select="true"
                                                                    :options="tagsGroup"
                                                                    :groups="true"
                                                                    group-label="type"
                                                                    group-options="tags"
                                                                    :group-hide-empty="true"
                                                                    placeholder="Select Tags"
                                                                >
                                                                    <template v-slot:grouplabel="{ group }">
                                                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                                    </template>
                                                                    <template v-slot:option="{ option }">
                                                                        <span>{{ option.name }}</span>
                                                                    </template>
                                                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                                        {{ option.name }}
                                                                        <span
                                                                            v-if="!disabled"
                                                                            class="multiselect-tag-remove"
                                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                                        >
                                                                            <span class="multiselect-tag-remove-icon"></span>
                                                                        </span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="tag" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div v-if="createTags" class="form_grp">
                                                    <Form v-slot="{ errors }" ref="tag-form">
                                                        <div class="group_item">
                                                            <label class="input_label">Create a New Tag</label>
                                                            <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                                <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                                <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                                            </div>
                                                            <ErrorMessage name="name" class="text-danger" />
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Issue Rewards for Submission</h5>
                                                <label for="issue_reward" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="issue_reward" :true-value="1" :false-value="0" v-model="form.reward_details.status" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div v-if="form.reward_details.status == 1">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Points Awarded</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.points_awarded }">
                                                            <Field autocomplete="off" tye="number" name="points_awarded" placeholder="ex: 500" v-model.number="form.reward_details.points_awarded" @keyup="form.reward_details.points_awarded = Number(form.reward_details.points_awarded).toString();"  rules="numeric" label="point" />
                                                        </div>
                                                        <ErrorMessage name="points_awarded" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Subscriber Reward Notifications</h5>
                                                    <label for="reward_notify" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="reward_notify" :true-value="1" :false-value="0" v-model="form.reward_details.subscriber_notifications" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="edit_section border mb-2" v-if="form.reward_details.subscriber_notifications">
                                                    <sending-method v-model="form.reward_details.notification_method" />
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">You just earned 0 points for</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.reward_reason }">
                                                            <Field autocomplete="off" tye="text" name="reward_reason" placeholder="Type here..." v-model="form.reward_details.reward_reason" />
                                                        </div>
                                                        <ErrorMessage name="reward_reason" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Send copy to respondent</h5>
                                                <label for="send_respondent_copy" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="send_respondent_copy" :true-value="1" :false-value="0" v-model="form.send_copy" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="mb-2" v-if="form.send_copy">
                                                <h3 class="sub_header">Notification method</h3>
                                                <div class="edit_section border mb-2">
                                                    <sending-method v-model="form.respondent_message.option" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="setting_wpr mt-3" v-if="form.send_copy">
                                        <div v-if="form.respondent_message.option == 1 || form.respondent_message.option == 3">
                                            <email-component v-model="respondentEmail" :errors="errors" :handle-default-email="handleRespondentDefaultEmail" preview-module="forms" ref="respondent-email-component" />
                                        </div>
                                        <div v-if="form.respondent_message.option == 2 || form.respondent_message.option == 3" class="sms-component">
                                            <sms-component v-model="form.respondent_message.sms_message" :media="respondentSmsMedia" media-field-name="respondentSmsMedia" :default-sms-handler="true" preview-module="forms" :handle-default-sms="handleRespondentDefaultSms" />
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100">
                                    <div class="section_header">
                                        <h2>Submit Button Settings</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text <a class="reset-default" @click="form.submit_label.label = defaultForm.submit_label.label">Reset to default</a></label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.button_text }">
                                                        <Field autocomplete="off" type="text" name="button_text" placeholder="ex: Submit" v-model="form.submit_label.label" />
                                                    </div>
                                                    <ErrorMessage name="button_text" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color <a class="reset-default" @click="form.submit_label.btncolor = defaultForm.submit_label.btncolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="button_color" v-model="form.submit_label.btncolor" type="text" label="button color">
                                                        <color-picker v-model="form.submit_label.btncolor" :classes="{ 'has-error': errors.button_color }" />
                                                    </Field>
                                                    <ErrorMessage name="button_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color <a class="reset-default" @click="form.submit_label.btntextcolor = defaultForm.submit_label.btntextcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="button_text_color" v-model="form.submit_label.btntextcolor" type="text" label="button color">
                                                        <color-picker v-model="form.submit_label.btntextcolor" :classes="{ 'has-error': errors.button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <h3 class="sub_header mt-5">Button Position <a class="reset-default" @click="form.submit_label.position = defaultForm.submit_label.position">Reset to default</a></h3>
                                            <ul class="alignment_area">
                                                <li>
                                                    <label for="left">
                                                        <input type="radio" id="left" value="left" v-model="form.submit_label.position" hidden>
                                                        <img src="@/assets/images/left.svg" alt="">
                                                        <h5>Left Align</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="center">
                                                        <input type="radio" id="center" value="center" v-model="form.submit_label.position" hidden>
                                                        <img src="@/assets/images/center.svg" alt="">
                                                        <h5>Center Align</h5>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label for="right">
                                                        <input type="radio" id="right" value="right" v-model="form.submit_label.position" hidden>
                                                        <img src="@/assets/images/right.svg" alt="">
                                                        <h5>Right Align</h5>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content_wpr" v-show="step == 3" data-step="3">
                                <div class="section_content w-100">
                                    <div class="section_header">
                                        <h2>Thank You Page Settings</h2>
                                    </div>
                                    <div class="color_container">
                                        <div class="sm_switch">
                                            <div class="capsule_elm pb-2 mb-2 border-bottom">
                                                <h5>Use Default</h5>
                                                <label for="default" class="switch_option capsule_btn">
                                                    <input type="radio" id="default" :value="1" v-model="form.page_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm pb-2 mb-2 border-bottom">
                                                <h5>Custom Design</h5>
                                                <label for="custom" class="switch_option capsule_btn">
                                                    <input type="radio" id="custom" :value="2" v-model="form.page_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Redirect</h5>
                                                <label for="redirect" class="switch_option capsule_btn">
                                                    <input type="radio" id="redirect" :value="3" v-model="form.page_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-3" v-if="form.page_type !== 3">
                                        <div class="setting_wpr">
                                            <div class="capsule_elm">
                                                <h5>Keep Current Cover Settings</h5>
                                                <label for="current_cover" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="current_cover" :true-value="1" :false-value="0" v-model="form.thankyou_use_current_cover" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div v-if="!form.thankyou_use_current_cover">
                                                    <h3 class="sub_header mt-4">
                                                        <div>
                                                            Thank you page cover
                                                            <h6 v-if="(form.thankyou_use_current_cover && !form.small_cover) || (!form.thankyou_use_current_cover && !form.thankyou_cover_small)">
                                                                Upload a small screen cover for a better view
                                                            </h6>
                                                        </div>
                                                    </h3>
                                                    <image-library v-model="form.thankyou_cover_image" image-type="public-cover" upload-text="Cover" />
                                                    <div class="capsule_elm">
                                                        <h5>Small Screen Image</h5>
                                                        <label for="small_image_thankyou" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="small_image_thankyou" :true-value="1" :false-value="0" v-model="form.has_thankyou_small_cover" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <image-library v-if="form.has_thankyou_small_cover" v-model="form.thankyou_cover_small" image-type="public-cover-small" upload-text="Cover" />
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                    <div class="color_container mt-3" v-if="form.page_type == 2">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Custom Headline
                                                        <div class="flex-center">
                                                            <button type="button" class="reset-default" @click="form.header = defaultForm.header">Reset to default</button>
                                                            <button type="button" class="px-1" @click="customHeadlineSetting = !customHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                        </div>
                                                    </label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.header }">
                                                        <Field autocomplete="off" type="text" name="header" placeholder="ex: Smart Step" v-model="form.header" />
                                                    </div>
                                                    <ErrorMessage name="header" class="text-danger" />
                                                </div>
                                            </div>
                                            <div v-if="customHeadlineSetting">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Color <a class="reset-default" @click="form.custom_headline_setting.font_color = defaultForm.custom_headline_setting.font_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="custom_headline_setting_font_color" v-model="form.custom_headline_setting.font_color" type="text" label="element color">
                                                            <color-picker v-model="form.custom_headline_setting.font_color" :classes="{ 'has-error': errors.custom_headline_setting_font_color }" />
                                                        </Field>
                                                        <ErrorMessage name="custom_headline_setting_font_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Custom Sub Headline
                                                        <div class="flex-center">
                                                            <button type="button" class="reset-default" @click="form.sub_header = defaultForm.sub_header">Reset to default</button>
                                                            <button type="button" class="px-1" @click="customSubHeadlineSetting = !customSubHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                        </div>
                                                    </label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.sub_header }">
                                                        <Field autocomplete="off" type="text" name="sub_header" placeholder="ex: Smart Step" v-model="form.sub_header" />
                                                    </div>
                                                    <ErrorMessage name="sub_header" class="text-danger" />
                                                </div>
                                            </div>
                                            <div v-if="customSubHeadlineSetting">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Color <a class="reset-default" @click="form.custom_subheadline_setting.font_color = defaultForm.custom_subheadline_setting.font_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="custom_subheadline_setting_font_color" v-model="form.custom_subheadline_setting.font_color" type="text" label="element color">
                                                            <color-picker v-model="form.custom_subheadline_setting.font_color" :classes="{ 'has-error': errors.custom_subheadline_setting_font_color }" />
                                                        </Field>
                                                        <ErrorMessage name="custom_subheadline_setting_font_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-3" v-if="form.page_type == 1 || form.page_type == 2">
                                        <div class="setting_wpr">
                                            <h3 class="sub_header mt-1 mb-2">Custom Page Content</h3>
                                            <div class="form_grp" v-if="form.page_type == 1">
                                                <div class="group_item">
                                                    <label class="input_label">Message <a class="reset-default" @click="form.thankyou_message = defaultForm.thankyou_message">Reset to default</a></label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.thankyou_message }">
                                                        <Field autocomplete="off" type="textarea" name="thankyou_message" v-model="form.thankyou_message">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.thankyou_message"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="thankyou_message" class="text-danger" />
                                                </div>
                                            </div>
                                            <div v-if="form.page_type == 2">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Page Content</label>
                                                        <Field autocomplete="off" type="textarea" name="content" v-model="form.content">
                                                            <redactor v-model="form.content" name="content" :default-value="selectedForm.content" :reset-watcher="contentResetWatcher" />
                                                        </Field>
                                                        <ErrorMessage name="content" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="capsule_elm mt-3">
                                                    <h5>Include A Video</h5>
                                                    <label for="allow_form_video" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="allow_form_video" :true-value="1" :false-value="0" v-model="form.has_thankyou_video_settings" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="edit_section border mt-1 mb-5" v-if="form.has_thankyou_video_settings">
                                                    <div class="border-bottom pb-2">
                                                        <div class="capsule_elm">
                                                            <h5>Video Link</h5>
                                                            <label for="vid_url" class="switch_option capsule_btn">
                                                                <input type="radio" id="vid_url" :value="2" v-model="form.video.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr mb-2" v-if="form.video.option === 2">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.thankyou_video_url }">
                                                                        <Field autocomplete="off" type="text" name="thankyou_video_url" placeholder="Video Link Goes Here..." v-model="form.video.url" rules="url" label="thankyou video url" />
                                                                        <span class="prefix">URL</span>
                                                                    </div>
                                                                    <ErrorMessage name="thankyou_video_url" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border-bottom py-2">
                                                        <div class="capsule_elm">
                                                            <h5>Embeded Video</h5>
                                                            <label for="emb_vid" class="switch_option capsule_btn">
                                                                <input type="radio" id="emb_vid" :value="1" v-model="form.video.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr" v-if="form.video.option === 1">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <div class="field_wpr" :class="{ 'has-error': errors.thankyou_video_embed }">
                                                                        <Field autocomplete="off" type="textarea" name="thankyou_video_embed" v-model="form.video.embed">
                                                                            <textarea cols="10" rows="10" placeholder="Embeded Code Goes Here..." v-model="form.video.embed"></textarea>
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage name="thankyou_video_embed" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="pt-2">
                                                        <div class="capsule_elm">
                                                            <h5>Image</h5>
                                                            <label for="add_img" class="switch_option capsule_btn">
                                                                <input type="radio" id="add_img" :value="3" v-model="form.video.option" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="upload_image mt-3" v-if="form.video.option === 3">
                                                            <image-library v-model="form.video.file" image-type="offer" upload-text="Image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color <a class="reset-default" @click="form.thankyou_bgcolor = defaultForm.thankyou_bgcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_bgcolor" v-model="form.thankyou_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.thankyou_bgcolor" :classes="{ 'has-error': errors.thankyou_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.thankyou_textcolor = defaultForm.thankyou_textcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_textcolor" v-model="form.thankyou_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.thankyou_textcolor" :classes="{ 'has-error': errors.thankyou_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-3" v-if="form.page_type == 3">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Redirect Page</label>
                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.url }">
                                                        <Field autocomplete="off" tye="text" name="url" placeholder="https://superfitcoaching.com" v-model="form.url" rules="url" label="redirect page" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="url" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="capsule_elm">
                                                <h5>Open In A New Tab</h5>
                                                <label for="blank_tab" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="blank_tab" :true-value="1" :false-value="0" v-model="form.thankyou_redirect_new_tab" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content_wpr" v-show="step == 4" data-step="4">
                                <div class="section_content w-100 open_area pb-0">
                                    <ul class="tab_sec">
                                        <li @click="tab = 'notifications'" :class="{ active: tab === 'notifications' }">Notifications</li>
                                        <li @click="tab = 'delivery'" :class="{ active: tab === 'delivery' }">Delivery Message</li>
                                    </ul>
                                </div>
                                <div class="section_content w-100" v-if="tab === 'notifications'">
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h3 class="sub_header m-0">Notification</h3>
                                            <label for="form_notification" class="switch_option capsule_btn">
                                                <input type="checkbox" id="form_notification" :true-value="1" :false-value="0" v-model="form.notification" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="mt-2" v-if="form.notification">
                                            <div class="edit_section border mb-2">
                                                <sending-method v-model="form.notification_prop.option" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3" v-if="form.notification">
                                        <div class="setting_wpr" v-if="form.notification_prop.option == 1 || form.notification_prop.option == 3">
                                            <email-component v-model="notificationEmail" :has-recipient="true" :errors="errors" :handle-default-email="handleNotificationDefaultEmail" preview-module="forms" ref="notification-email-component" />
                                        </div>
                                        <div class="setting_wpr" v-if="form.notification_prop.option == 2 || form.notification_prop.option == 3">
                                            <sms-component v-model="form.notification_prop.sms_message" :media="notificationSmsMedia" :number="form.notification_prop.sms_recipient" :handle-update="handleNotificationSmsChange" :has-number-field="true" media-field-name="notificationSmsMedia" preview-module="forms" :default-sms-handler="true" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notif-sms-component" />
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100" v-if="tab === 'delivery'">
                                    <div class="section_header">
                                        <h2>Delivery Message</h2>
                                    </div>
                                    <div class="color_container mt-1 delivery-message">
                                        <div class="capsule_elm">
                                            <h3 class="sub_header mt-1">Notification</h3>
                                            <label for="delivery-notification-status" class="switch_option capsule_btn">
                                                <input type="checkbox" id="delivery-notification-status" :true-value="1" :false-value="0" v-model="form.delivery_notification_status" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div v-show="form.delivery_notification_status">
                                            <ul class="tab_sec">
                                                <li :class="deliveryMessageTab === 'email' ? 'active' : ''" @click="deliveryMessageTab = 'email'">Email</li>
                                                <li :class="deliveryMessageTab === 'sms' ? 'active' : ''" @click="deliveryMessageTab = 'sms'">SMS</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="mt-3" v-show="form.delivery_notification_status">
                                        <div class="setting_wpr" v-show="deliveryMessageTab == 'email'">
                                            <email-component v-model="deliveryEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleDeliveryDefaultEmail" preview-module="forms" ref="delivery-email-component" is-full-view />
                                        </div>
                                        <div class="setting_wpr sms-component" v-show="deliveryMessageTab == 'sms'">
                                            <sms-component v-model="form.survey_message.sms_message" :media="deliverySmsMedia" :default-sms-handler="true" :handle-default-sms="handleDeliveryDefaultSms" media-field-name="deliverySmsMedia"  preview-module="forms" :update-sms-text="updateSmsText()" ref="survey-sms-component" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content_wpr" v-show="step == 5" data-step="5">
                                <div class="section_content w-100 open_area">
                                    <div class="section_header">
                                        <h2>Tracking</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Form tracking code</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                        <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.fb_pixel">
                                                            <textarea cols="10" rows="10" placeholder="Form tracking code Goes Here..." v-model="form.fb_pixel"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="fb_pixel" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Thank you page tracking code</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel_thankyou }">
                                                        <Field autocomplete="off" type="textarea" name="fb_pixel_thankyou" v-model="form.fb_pixel_thankyou">
                                                            <textarea cols="10" rows="10" placeholder="Thank you page tracking code Goes Here..." v-model="form.fb_pixel_thankyou"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="fb_pixel_thankyou" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                    <div class="section_header">
                                        <h2>SEO Settings</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                        <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.seo_title" />
                                                    </div>
                                                    <ErrorMessage name="seo_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Keywords</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                        <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.seo_keyword" />
                                                    </div>
                                                    <ErrorMessage name="seo_keyword" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                        <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_desc"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="seo_desc" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm">
                                            <h5>I want my page indexed by search engine</h5>
                                            <label for="page_indexed" class="switch_option capsule_btn">
                                                <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.seo_index" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                    <div class="section_header">
                                        <h2>Social Share</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                        <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.seo_share_title" />
                                                    </div>
                                                    <ErrorMessage name="seo_share_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                        <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.seo_share_desc">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.seo_share_desc"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="seo_share_desc" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                        <image-library v-model="form.seo_thumb" image-type="social-share" />
                                    </div>
                                </div>
                                <div class="section_content w-100" @click="goTo('favsection')" ref="favsetting">
                                    <div class="section_header">
                                        <h2>Favicon</h2>
                                    </div>
                                    <div class="color_container mt-1">
                                        <image-library v-model="form.seo_fav" image-type="favicon" />
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                        </div>
                        <div class="action_wpr">
                            <button :disabled="formUpdateLoader" type="button" class="btn cancel_btn" @click="$router.push({ name: 'FormIndex' });">Exit</button>
                            <button :disabled="formUpdateLoader" type="button" class="btn cancel_btn" @click="step--" v-if="step > 0">Prev</button>
                            <button :disabled="formUpdateLoader" class="btn save_btn" id="save-form-button" v-if="step < 5">
                                <i class="fa fa-spin fa-spinner" v-if="formUpdateLoader"></i> {{ formUpdateLoader ? 'Updating' : 'Next' }}
                            </button>
                            <button :disabled="formUpdateLoader" class="btn save_btn" v-if="step == 5">
                                <i class="fa fa-spin fa-spinner" v-if="formUpdateLoader"></i>&nbsp; {{ formUpdateLoader ? 'Updating' : 'Finish'}}
                            </button>
                        </div>
                    </Form>
                </div>
                <div class="preview_content" :class="{ show : preview }" v-if="step <= 2">
                    <div class="header">
                        <div class="section_header m-0">
                            <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                            <h2>Preview</h2>
                            <label for="preview_form" class="switch_option capsule_btn p-0">
                                <h5 class="mr-3">Preview Form</h5>
                                <input type="checkbox" id="preview_form" v-model="previewForm" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="content_area">
                            <div class="dashboard_content">
                                <div class="client_card border-0 mb-5">
                                    <div class="card_header" ref="hdrsection" @click="setting('hdrsetting')" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" v-show="form.display_logo">
                                        <nav>
                                            <img class="logo_img" :src="form.logo ? form.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                            <a class="dropdown-item dashboard-menu-text pointer">
                                                <h4>Dashboard</h4>
                                            </a>
                                        </nav>
                                        <div class="user_box" @click="accountDrop = !accountDrop">
                                            <img :src="form.author ? form.author : require('@/assets/images/default-avatar.svg')" alt="chris">
                                            <div class="user_info">
                                                <h5>{{ user.full_name }}</h5>
                                            </div>
                                            <ul class="account_drop" :class="{ active : accountDrop }">
                                                <li><i class="fas fa-home"></i> Primary Location</li>
                                                <li><i class="far fa-user"></i> Profile Info</li>
                                                <li><i class="far fa-file"></i> My Files</li>
                                                <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card_body" ref="cvrsection" @click="setting('cvrsetting')" v-show="form.display_hero && form.has_small_screen_cover " :class="{'cover-image-sm' : form.cover_type == 1}">
                                        <!-- <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                            <source v-if="form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                            <img :src="form.has_small_screen_cover && form.small_cover ? form.small_cover : (form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                                        </picture> -->
                                        <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                            <source v-if="form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                            <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                        </picture>
                                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                        <div class="banner_content">
                                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3}px; line-height: ${form.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.other_header_info }}</h4>
                                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3}px; line-height: ${form.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(form.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                                        </div>
                                    </div>
                                </div>
                                <ul class="mobile_menu px-3">
                                    <li v-if="started" @click="started = !started"><i class="fas fa-angle-left"></i>Back</li>
                                    <li @click="openSlide()" class="ml-auto" v-if="selectedForm.display_coach">
                                        <svg width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0717 43.5574C7.3429 36.5812 15.646 32.0607 25.0001 32.0607C34.3541 32.0607 42.6572 36.5812 47.9283 43.5574C47.9521 43.5889 47.9761 43.6207 48.0001 43.6522C48.4533 44.2517 48.9393 44.8943 49.2866 45.5144C49.7068 46.2649 50.0186 47.1329 49.9991 48.1888C49.9836 49.0373 49.7266 49.8319 49.4143 50.4592C49.102 51.0865 48.6232 51.7705 47.9555 52.2947C47.0648 52.9936 46.0952 53.241 45.2338 53.3441C44.4783 53.4347 43.5827 53.4347 42.6824 53.4344C42.6417 53.4344 42.6011 53.4344 42.5605 53.4344H7.43943C7.39885 53.4344 7.35827 53.4344 7.31771 53.4344C6.41734 53.4347 5.5218 53.4347 4.76613 53.3441C3.9048 53.241 2.9352 52.9936 2.04467 52.2947C1.37687 51.7705 0.898042 51.0865 0.585771 50.4592C0.273501 49.8319 0.0163741 49.0373 0.000851407 48.1888C-0.0184651 47.1329 0.293084 46.2649 0.713453 45.5144C1.06072 44.8943 1.54671 44.2517 1.99991 43.6522C2.02396 43.6207 2.04787 43.5889 2.0717 43.5574Z" fill="#999999"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3047 14.6945C10.3047 6.57893 16.8836 0 24.9992 0C33.1146 0 39.6937 6.57893 39.6937 14.6945C39.6937 22.8101 33.1146 29.3889 24.9992 29.3889C16.8836 29.3889 10.3047 22.8101 10.3047 14.6945Z" fill="#999999"/>
                                        </svg>
                                        Coach
                                    </li>
                                </ul>
                                <div class="left_side mb-5" ref="optsection" @click="setting('optsetting')">
                                    <div class="progress_sec" v-if="form.question_view == 1 && form.display_progressbar" >
                                        <h3 class="mb-2">{{ progress.completed }} of {{ progress.total }} Questions Completed</h3>
                                        <div class="status mb-2">
                                            <span :style="`background-color: ${form.progressbar_color}; width: ${ progress.percentage }%;`"></span>
                                        </div>
                                    </div>
                                    <div class="section_item pt-0" v-if="!started">
                                        <div class="video_wpr" v-if="(form.instruction_settings.option == 1 && form.instruction_settings.embed) || (form.instruction_settings.option == 2 && form.instruction_settings.url)">
                                            <div v-if="form.instruction_settings.option == 2" v-html="parseEmbedCode(form.instruction_settings.url)"></div>
                                            <div v-else v-html="form.instruction_settings.embed"></div>
                                        </div>
                                        <img v-else-if="form.instruction_settings.option == 3" :src="form.instruction_settings.file ? form.instruction_settings.file :  require('@/assets/images/thumb/default-cover-3-2.svg')" class="start_screen_placeholder"/>
                                        <img v-else src="@/assets/images/thumb/video-placeholder.svg" class="start_screen_placeholder"/>
                                    </div>
                                    <button v-if="!started" type="button" class="primary_btn mt-3 mb-5 pointer" @click="started = true" :style="`color: ${form.instruction_settings.btntextcolor}; background: ${form.instruction_settings.btncolor};`">{{ form.instruction_settings.label ?  form.instruction_settings.label : 'Get Started' }}</button>
                                    <div class="section_item border_item p-0" v-if="form.question_view == 1 && started">
                                        <div ref="qstsection" @click="setting('qstsetting')">
                                            <wizard-form ref="form-content" v-model="progress" :active-form="form" />
                                        </div>
                                    </div>
                                    <div class="section_item border_item pt-2" v-if="form.question_view == 2  && started">
                                        <div ref="qstsection" @click="setting('qstsetting')">
                                            <long-form ref="form-content" :active-form="form" />
                                        </div>
                                    </div>
                                </div>
                                <div class="right_side mb-5 pb-5" v-if="selectedForm.display_coach && started">
                                    <div class="section_item coach_card">
                                        <h3 :style="`color: ${selectedForm.title_text_color};`">{{ selectedForm.coach_headline ? selectedForm.coach_headline : 'Coach Headline' }}</h3>
                                        <div class="user_box">
                                            <img :src="selectedForm.coach_image ? selectedForm.coach_image : require('@/assets/images/default-avatar.svg')" alt="chris">
                                            <div class="user_info">
                                                <h3>{{ selectedForm.coach_name ? selectedForm.coach_name : 'Joe Trainer' }}</h3>
                                                <h5>{{ selectedForm.coach_title ? selectedForm.coach_title : 'PHD, CSCS' }}</h5>
                                            </div>
                                        </div>
                                        <p v-html="selectedForm.coach_bio ? selectedForm.coach_bio : 'Joe Trainer, your fitness guru, has been shaping better bodies and healthier lifestyles for over two decades. With a PhD in Pumping Hearts and a CSCS (Certified Strong Coffee Sipper), Joe is more than your average coach. When he\'s not helping clients like you reach their fitness goals, he\'s probably creating a new protein shake recipe or lifting large animals just for fun.'"></p>
                                    </div>
                                </div>
                                <div ref="ftrsection" @click="setting('ftrsetting')">
                                    <footer class="dashboard_footer" v-if="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                        <ul>
                                            <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                            <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                            <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                            <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                            <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                        </ul>
                                        <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company }}</h4>
                                        <p>
                                            <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                            <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                            <span v-html="selectedForm.white_label"></span>
                                        </p>
                                    </footer>
                                </div>
                            </div>
                        </div>
                        <!-- Instructor -->
                        <div class="slide_box_container" v-if="selectedForm.display_coach" :style="`pointer-events:${instructorDetails ? 'all' : 'none'}`">
                            <div class="slide_box" :class="{'active' : instructorDetails}">
                                <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                                <div class="box_container">
                                    <div class="instructor">
                                        <h3 :style="`color: ${selectedForm.title_text_color};`">{{ selectedForm.coach_headline ? selectedForm.coach_headline : 'Coach Headline' }}</h3>
                                        <div class="user_box">
                                            <img :src="selectedForm.coach_image ? selectedForm.coach_image : require('@/assets/images/default-avatar.svg')" alt="chris">
                                            <div class="user_info">
                                                <h3>{{ selectedForm.coach_name ? selectedForm.coach_name : 'Joe Trainer' }}</h3>
                                                <h5>{{ selectedForm.coach_title ? selectedForm.coach_title : 'PHD, CSCS' }}</h5>
                                            </div>
                                        </div>
                                        <p v-html="selectedForm.coach_bio ? selectedForm.coach_bio : 'Joe Trainer, your fitness guru, has been shaping better bodies and healthier lifestyles for over two decades. With a PhD in Pumping Hearts and a CSCS (Certified Strong Coffee Sipper), Joe is more than your average coach. When he\'s not helping clients like you reach their fitness goals, he\'s probably creating a new protein shake recipe or lifting large animals just for fun.'"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview_content" :class="{ show : preview }" v-if="step == 3">
                    <div class="header">
                        <div class="section_header m-0">
                            <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                            <h2>Preview</h2>
                            <label for="preview_form" class="switch_option capsule_btn p-0">
                                <h5 class="mr-3">Preview Form</h5>
                                <input type="checkbox" id="preview_form" v-model="previewForm" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                    <div class="cell">
                        <div class="content_area">
                            <div class="dashboard_content">
                                <template v-if="form.page_type == 3">
                                    <div class="newsletter_redirect">
                                        <div class="redirect_add">
                                            <label class="prefix_area"><i class="fas fa-globe-americas"></i><span></span></label>
                                            <input type="text" v-model="form.url">
                                            <span class="btn"><i class="fas fa-search"></i></span>
                                        </div>
                                        <img src="@/assets/images/redirect.svg" alt="">
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="client_card">
                                        <div class="card_header" @click="setting('hdrsetting', 'thank')" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" v-show="form.display_logo">
                                            <nav>
                                                <img class="logo_img" :src="form.logo ? form.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                                <a class="dropdown-item dashboard-menu-text pointer">
                                                    <h4>Dashboard</h4>
                                                </a>
                                            </nav>
                                            <div class="user_box" @click="accountDrop = !accountDrop">
                                                <img src="@/assets/images/default-avatar.svg" alt="chris">
                                                <div class="user_info">
                                                    <h5>{{ user.full_name }}</h5>
                                                </div>
                                                <ul class="account_drop" :class="{ active : accountDrop }">
                                                    <li><i class="fas fa-home"></i> Primary Location</li>
                                                    <li><i class="far fa-user"></i> Profile Info</li>
                                                    <li><i class="far fa-file"></i> My Files</li>
                                                    <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card_body" @click="setting('cvrsetting', 'thank')" v-if="(form.page_type == 1 || form.page_type == 2 ) && form.thankyou_use_current_cover && form.display_hero && form.has_small_screen_cover" :class="{'cover-image-sm' : form.cover_type == 1}">
                                            <picture v-if="form.cover_type == 2 || form.cover_type == 3">
                                                <source v-if="form.display_hero && form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                                <img v-if="form.display_hero" :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                            </picture>
                                            <!-- <picture v-if="form.thankyou_use_current_cover && (form.cover_type == 2 || form.cover_type == 3)">
                                                <source v-if="form.display_hero && form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                                <img v-if="form.display_hero" :src="form.has_small_screen_cover && form.small_cover ? form.small_cover : (form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                                            </picture> -->
                                            <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                            <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                            <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                            <div class="banner_content">
                                                <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3}px; line-height: ${form.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.header ? form.header : form.other_header_info }}</h4>
                                                <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3}px; line-height: ${form.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(form.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_header ? form.sub_header : form.description }}</h1>
                                            </div>
                                        </div>
                                        <div class="card_body" @click="setting('cvrsetting', 'thank')" v-if="(form.page_type == 1 || form.page_type == 2) && !form.thankyou_use_current_cover && form.has_thankyou_small_cover " :class="{'cover-image-sm' : form.cover_type == 1}">
                                            <picture>
                                                <source v-if="form.thankyou_cover_small != null && form.thankyou_cover_small != '' && form.thankyou_cover_small != 'null'" :srcset="`${form.thankyou_cover_small}`" media="(max-width: 768px)">
                                                <img :src="form.thankyou_cover_small ? form.thankyou_cover_small : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                            </picture>
                                            <!-- <picture>
                                                <source v-if="form.has_thankyou_small_cover && (form.thankyou_cover_small != null && form.thankyou_cover_small != '' && form.thankyou_cover_small != 'null')" :srcset="`${form.thankyou_cover_small}`" media="(max-width: 768px)">
                                                <img :src="form.has_thankyou_small_cover && form.thankyou_cover_small ? form.thankyou_cover_small : (form.has_thankyou_small_cover == 0 && form.thankyou_cover_image ? form.thankyou_cover_image : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                                            </picture> -->
                                            <span class="overlay" style="background-color: #000; opacity: 0.05;"></span>
                                            <div class="banner_content">
                                                <h2 :style="`color: ${form.custom_headline_setting.font_color};`">{{ form.header }}</h2>
                                                <h6 :style="`color: ${form.custom_subheadline_setting.font_color};`">{{ form.sub_header }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="thank_content" @click="setting('thanksetting', 'thank')">
                                        <div class="feedback" :style="`background: ${form.thankyou_bgcolor}; border-color: ${form.thankyou_bgcolor}; color: ${form.thankyou_textcolor};`">
                                            <div v-if="form.has_thankyou_video_settings && (form.video.embed || form.video.url) && (form.video.option == 1 || form.video.option == 2)" class="section_item p-0">
                                                <div v-if="form.video.option == 1" v-html="form.video.embed" class="video_wpr mb-0"></div>
                                                <div v-else-if="form.video.option == 2" v-html="parseEmbedCode(form.video.url)" class="video_wpr mb-0"></div>
                                            </div>
                                            <div class="redactor-styles redactor-in thankyou_content" :style="`color: ${form.thankyou_textcolor};`" v-html="form.thankyou_message" v-if="form.page_type == 1"></div>
                                            <div class="redactor-styles redactor-in thankyou_content" :style="`color: ${form.thankyou_textcolor};`" v-html="replaceVariables(form.content)" v-if="form.page_type == 2"></div>
                                        </div>
                                    </div>
                                    <footer @click="setting('ftrsetting', 'thank')" class="dashboard_footer" v-if="form.display_footer" :style="`background: ${selectedForm.footer_bgcolor};`">
                                        <ul>
                                            <li class="pointer" v-if="selectedForm.has_facebook"><a :href="selectedForm.footer_facebook" :style="`border-color: ${selectedForm.footer_textcolor}; color: ${selectedForm.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                            <li class="pointer" v-if="selectedForm.has_twitter"><a :href="selectedForm.footer_twitter" :style="`border-color: ${selectedForm.footer_textcolor}; color: ${selectedForm.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                            <li class="pointer" v-if="selectedForm.has_instagram"><a :href="selectedForm.footer_instagram" :style="`border-color: ${selectedForm.footer_textcolor}; color: ${selectedForm.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                            <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                            <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                        </ul>
                                        <h4 :style="`color: ${selectedForm.footer_textcolor};`">© {{ selectedForm.footer_company }}</h4>
                                        <p>
                                            <a :href="selectedForm.footer_terms" :style="`color: ${selectedForm.footer_textcolor};`">Terms</a>
                                            <a :href="selectedForm.footer_policy" :style="`color: ${selectedForm.footer_textcolor};`">Privacy</a>
                                            <span v-html="selectedForm.white_label"></span>
                                        </p>
                                    </footer>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview_content" :class="{ show : preview }" v-if="step == 4 && tab === 'notifications'">
                    <div class="header">
                        <div class="section_header m-0">
                            <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                            <h2>Preview</h2>
                            <label for="preview_notification" class="switch_option capsule_btn p-0 border-0">
                                <h5 class="mr-3">Full View</h5>
                                <input type="checkbox" id="preview_notification" v-model="previewNotific" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                    <ul class="alt_prev">
                        <li @click="notificType = 'email'" :class="{'active' : notificType == 'email'}" v-if="form.notification_prop.option == 1 || form.notification_prop.option == 3">E-mail</li>
                        <li @click="notificType = 'sms'" :class="{'active' : notificType == 'sms'}" v-if="form.notification_prop.option == 2 || form.notification_prop.option == 3">SMS</li>
                    </ul>
                    <div class="cell">
                        <div class="content_area" v-if="(notificType == 'email' && form.notification_prop.option == 3) || form.notification_prop.option == 1">
                            <div class="msg_preview">
                                <h2>{{ replaceVariables(notificationEmail.subject) }}</h2>
                                <div class="sender_info">
                                    <img src="@/assets/images/default-avatar.svg" alt="">
                                    <div class="info">
                                        <h5>Thrive Coach</h5>
                                        <h6><span>To:</span>{{ user.full_name }}</h6>
                                    </div>
                                </div>
                                <div class="email-logo" v-if="notificationEmail.show_email_logo">
                                    <img :src="notificationEmail.email_logo ? notificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                                </div>
                                <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(notificationEmail.email)"></div>
                                <div v-html="generateSignature(notificationEmail.is_signature, notificationEmail.signature_id)"></div>
                            </div>
                            <div class="msgFooter text-center mt-2 mb-2">
                                <p>
                                    <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                        <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                        <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    </template>
                                    <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                                </p>
                                <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                                <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                            </div>
                        </div>
                        <div class="content_area" v-if="(notificType == 'sms' && form.notification_prop.option == 3) || form.notification_prop.option == 2">
                            <div class="sms_preview">
                                <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(notificationSmsText)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview_content" :class="{ show : preview }" v-if="step == 4 && tab === 'delivery'">
                    <div class="header">
                        <div class="section_header m-0">
                            <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                            <h2>Preview</h2>
                            <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                                <h5 class="mr-3">Full View</h5>
                                <input type="checkbox" id="preview_msg" v-model="previewMsg" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                    <ul class="alt_prev">
                        <li @click="deliveryMessageTab = 'email'" :class="{'active' : deliveryMessageTab == 'email'}">E-mail</li>
                        <li @click="deliveryMessageTab = 'sms'" :class="{'active' : deliveryMessageTab == 'sms'}">SMS</li>
                    </ul>
                    <div class="cell">
                        <div class="content_area" v-if="deliveryMessageTab == 'email'">
                            <div class="msg_preview">
                                <h2>{{ replaceVariables(deliveryEmail.subject) }}</h2>
                                <div class="sender_info">
                                    <img src="@/assets/images/default-avatar.svg" alt="">
                                    <div class="info">
                                        <h5>Thrive Coach</h5>
                                        <h6><span>To:</span>{{ user.full_name }}</h6>
                                    </div>
                                </div>
                                <div class="email-logo" v-if="deliveryEmail.show_email_logo">
                                    <img :src="deliveryEmail.email_logo ? deliveryEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                                </div>
                                <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(deliveryEmail.email, deliveryEmail.email_button_bg_color, deliveryEmail.email_button_text_color)"></div>
                                <div v-html="generateSignature(deliveryEmail.is_signature, deliveryEmail.signature_id)"></div>
                            </div>
                            <div class="msgFooter text-center mt-2 mb-2">
                                <p>
                                    <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                        <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                        <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    </template>
                                    <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                                </p>
                                <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                                <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                            </div>
                        </div>
                        <div class="content_area" v-if="deliveryMessageTab == 'sms'">
                            <div class="sms_preview">
                                <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(surveySmsText)"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="preview_content" :class="{ show : preview }" v-if="step == 5">
                    <div class="header">
                        <div class="section_header m-0">
                            <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                            <h2>Preview</h2>
                        </div>
                    </div>
                    <div class="area_wpr">
                        <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                        <div class="google_ranking">
                            <h6>About 5,29,000 results(0.36 seconds)</h6>
                            <h4>{{ form.seo_title }}</h4>
                            <p>{{ form.seo_desc }}</p>
                        </div>
                    </div>
                    <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                        <h3 class="sub_header">Social Share</h3>
                        <div class="seo_card">
                            <img :src="form.seo_thumb ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                            <div class="seo_info">
                                <h3>{{ form.seo_share_title ? form.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                                <p>{{ form.seo_share_desc ? form.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                        <h3 class="sub_header">Favicon</h3>
                        <div class="browser_layout">
                            <ul class="browser_tabs">
                                <li>
                                    <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                    <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                    <i class="fas fa-times"></i>
                                </li>
                                <li><i class="fas fa-plus"></i></li>
                            </ul>
                            <div class="browser_info">
                                <i class="fas fa-arrow-left"></i>
                                <i class="fas fa-arrow-right"></i>
                                <i class="fas fa-redo"></i>
                                <div class="browser_search">
                                    <i class="fas fa-lock"></i>
                                    <p>https://thrivecoach.io</p>
                                    <i class="far fa-star ml-auto"></i>
                                </div>
                                <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                                <i class="fas fa-ellipsis-v"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <preview-form v-model="previewForm"  v-if="previewForm"  />
            <preview-form-notification v-model="previewNotific" v-if="previewNotific" />
            <preview-form-msg v-model="previewMsg" v-if="previewMsg" />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const CoachBioComponent  = defineAsyncComponent(() => import('@/components/CoachBioComponent'))
    const LongForm  = defineAsyncComponent(() => import('@/pages/form/components/LongForm'))
    const WizardForm  = defineAsyncComponent(() => import('@/pages/form/components/WizardForm'))
    const PreviewForm  = defineAsyncComponent(() => import('@/pages/form/components/PreviewForm'))
    const WidgetQuestionForm  = defineAsyncComponent(() => import('@/pages/form/components/WidgetQuestionForm'))
    const PreviewFormMsg  = defineAsyncComponent(() => import('./PreviewFormMsg'))
    const PreviewFormNotification  = defineAsyncComponent(() => import('./PreviewFormNotification'))

    import Draggable from 'vuedraggable'
    import VideoParser from '@/utils/VideoParser'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Form Global Setting',

        data () {
            return {
                tab: 'notifications',
                notificationTab: 'admin',
                step: 0,
                previewTab: '',
                deliveryMessageTab: 'email',
                notificType: 'email',
                headlineSetting: false,
                subHeadlineSetting: false,
                defaultCoachBio: '',
                resetToDefaultWatcher: 0,
                toggleAll: 1,
                form: {
                    headline_setting: {},
                    subheadline_setting: {},
                    accent_color: '#F2A31D',
                    ip_bgcolor: '#bdeafa',
                    ip_textcolor: '#2c3e50',
                    question_view: 1,
                    show_question_number: 1,
                    auto_advance: 0,
                    survey_name: '',
                    opt_in: 0,
                    optin_thankyou_message: '',
                    optin_headline: '',
                    has_optin_video: 0,
                    optin_video_type: 1,
                    optin_video_url: '',
                    optin_video_embed: '',
                    optin_file: '',
                    submit_with_member_id: 0,
                    add_ons: [],
                    type: 'Prospect',
                    is_signature: 0,
                    signature: '',
                    store_copy: 'Yes',
                    send_copy: 0,
                    has_optin_tagging: 0,
                    reward_details: {},
                    respondent_message: {},
                    tags: [],
                    submit_label: {},
                    notification: 0,
                    notification_prop: {},
                    company_branding: '',
                    display_logo: 0,
                    header_bgcolor: '#FFF',
                    header_textcolor: '#000',
                    logo: '',
                    author: '',
                    cover_type: 2,
                    display_hero: 0,
                    other_header_info: '',
                    description: '',
                    cover: '',
                    has_small_screen_cover: 0,
                    small_cover: '',
                    has_cover_overlay: 0,
                    overlay_color: '',
                    overlay_opacity: '',
                    cover_background_color: '',
                    display_footer: 0,
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    has_linkedin: 0,
                    has_youtube: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_linkedin: '',
                    footer_youtube: '',
                    footer_textcolor: '',
                    footer_bgcolor: '#f5f5f5',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    thankyou_use_current_cover: 0,
                    thankyou_cover_image: '',
                    thankyou_cover_small: '',
                    page_type: 1,
                    thankyou_message: '',
                    header: '',
                    sub_header: '',
                    content: '',
                    has_thankyou_video_settings: 0,
                    video: {},
                    url: '',
                    thankyou_redirect_new_tab: 0,
                    fb_pixel: '',
                    fb_pixel_thankyou: '',
                    seo_title: '',
                    seo_keyword: '',
                    seo_desc: '',
                    seo_index: 0,
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    seo_fav: '',
                    survey_message: {},
                    delivery_notification_status: 0,
                    thankyou_bgcolor: '',
                    thankyou_textcolor: '#2c3e50',
                    is_private: 0,
                    display_instructions: 0,
                    instruction_settings: {},
                    display_question_headline: 0,
                    question_headline: '',
                    display_progressbar: 0,
                    progressbar_color: '#2F7FED',
                    display_coach: 0,
                    coach_headline: '',
                    coach_name: '',
                    title: '',
                    coach_bio: '',
                    coach_image: '',
                    has_thankyou_small_cover: 1,
                    form_description:'',
                    apply_saved_styling: 0,
                },
                signatureResetWatcher: 0,
                contentResetWatcher: 0,
                thankyouCoverTab: 'big-screen',
                optinFields: [
                    {
                      title: 'First Name',
                      value: 'fname'
                    },
                    {
                      title: 'Last Name',
                      value: 'lname'
                    },
                    {
                      title: 'Phone',
                      value: 'phone'
                    },
                    {
                      title: 'Address',
                      value: 'address'
                    },
                    {
                      title: 'State',
                      value: 'state'
                    },
                    {
                      title: 'City',
                      value: 'city'
                    },
                    {
                      title: 'Country',
                      value: 'country'
                    },
                    {
                      title: 'Zip Code',
                      value: 'zip'
                    },
                    {
                      title: 'Date of Birth',
                      value: 'dob'
                    },
                ],
                respondentEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                deliveryEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
                whiteLabel: {},
                preview: true,
                previewForm: false,
                previewMsg: false,
                previewNotific: false,
                accountDrop: false,
                progress: {
                    total: 0,
                    completed: 0,
                    percentage: 0,
                },
                env: {},
                notificationSmsText: '',
                surveySmsText: '',
                respondentSmsMedia: [],
                notificationSmsMedia: [],
                deliverySmsMedia: [],
                customFields: [],
                customHeadlineSetting: false,
                customSubHeadlineSetting: false,
                started: false,
                questions: [],
                questionIndex: -1,
                instructorDetails: false,
            };
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            LongForm,
            Draggable,
            WizardForm,
            PreviewForm,
            PreviewFormMsg,
            CoachBioComponent,
            WidgetQuestionForm,
            PreviewFormNotification,
        },

        watch: {
            step (val) {
                const vm = this;

                if (val >= 0 && val < 3) {
                    vm.started = true;
                } else {
                    vm.started = false;
                }
            },

            previewForm (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');

                    const button = document.getElementById('save-form-button');

                    if (button) {
                        button.click();
                    }
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            customFieldsAll (fields) {
                const vm = this;

                Object.values(fields).forEach((field) => {
                    vm.customFields.push(field);
                });
            },

            previewMsg (val) {
                if (val) {
                    document.body.querySelector('#save-form-button').click();
                }
            },

            formQuestions (questions) {
                const vm = this;

                vm.questions = JSON.parse(JSON.stringify(questions));

                const el = document.getElementById(`question-accordion-${vm.questionIndex}`);

                if (el) {
                    el.classList.add('open');
                }
            },

            'form.display_instructions' (val) {
                const vm = this;

                if (!val) {
                    vm.started = true;
                } else {
                    vm.started = false;
                }
            },

            '$route.params' (params) {
                const vm = this;

                if (params.form) {
                    vm.getFormDetails(params.form);
                    vm.getFormQuestions(params.form);
                }
            },

            selectedForm (form) {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: {
            ...mapState({
                selectedForm: state => state.formModule.selectedForm,
                formUpdateLoader: state => state.formModule.formUpdateLoader,
                tagsGroup: state => state.tagModule.tagsGroup,
                tagLoader: state => state.tagModule.tagLoader,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                customFieldsAll: state => state.contactModule.customFieldsAll,
                formQuestions: state => state.formModule.questions,
                formDetailLoader: state => state.formModule.formDetailLoader,
                defaultForm: state => state.formModule.formDefaultSettings,
                formDefaultContent: state => state.commonModule.formDefaultContent,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.$route.params && vm.$route.params.form) {
                vm.getFormDetails(vm.$route.params.form);
                vm.getFormQuestions(vm.$route.params.form);
            }

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            if (!vm.customFieldsAll.length) {
                vm.getCustomFields({ active: 1, type: 'all' });
            }

            Object.values(vm.customFieldsAll).forEach((field) => {
                vm.customFields.push(field);
            });

            window.addEventListener("resize", vm.resizeEventHandler);
            vm.resizeEventHandler();

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            if (!Object.values(vm.formDefaultContent).length) {
                vm.getDefaultContentByType(3);
            }
        },

        unmounted() {
            const vm = this;
            window.removeEventListener("resize", vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                updateForm: 'formModule/updateForm',
                sortQuestions: 'formModule/sortQuestions',
                getFormQuestions: 'formModule/getFormQuestions',
                getCustomFields: 'contactModule/getCustomFields',
                getFormDetails: 'formModule/getFormDetails',
                deleteQuestion: 'formModule/deleteQuestion',
                getDefaultContentByType: 'commonModule/getDefaultContentByType',
            }),

            tabSwitch (tab) {
                const vm = this;

                vm.tab = tab;
            },

            resetForm () {
                const vm = this;

                let headline_setting = vm.selectedForm.headline_setting ? JSON.parse(JSON.stringify(vm.selectedForm.headline_setting)) : {};
                let subheadline_setting = vm.selectedForm.subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedForm.subheadline_setting)) : {};
                let custom_headline_setting = vm.selectedForm.custom_headline_setting ? JSON.parse(JSON.stringify(vm.selectedForm.custom_headline_setting)) : {};
                let custom_subheadline_setting = vm.selectedForm.custom_subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedForm.custom_subheadline_setting)) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }

                if (!custom_headline_setting.font_color) {
                    custom_headline_setting = {
                        font_color: '#2C3E50'
                    };
                }

                if (!custom_subheadline_setting.font_color) {
                    custom_subheadline_setting = {
                        font_color: '#2C3E50',
                    };
                }

                const applySavedStyling = vm.form.apply_saved_styling ? vm.form.apply_saved_styling : 0;

                vm.form = {
                    id: vm.selectedForm.id,
                    form_id: vm.selectedForm.id,
                    headline_setting,
                    subheadline_setting,
                    custom_headline_setting,
                    custom_subheadline_setting,
                    accent_color: vm.selectedForm.accent_color ? vm.selectedForm.accent_color : '#F2A31D',
                    ip_bgcolor: vm.selectedForm.ip_bgcolor ? vm.selectedForm.ip_bgcolor : '#bdeafa',
                    ip_textcolor: vm.selectedForm.ip_textcolor ? vm.selectedForm.ip_textcolor : '#2c3e50',
                    question_view: vm.selectedForm.question_view ? vm.selectedForm.question_view : 1,
                    show_question_number: vm.selectedForm.show_question_number ? vm.selectedForm.show_question_number : 0,
                    auto_advance: vm.selectedForm.auto_advance ? vm.selectedForm.auto_advance : 0,
                    survey_name: vm.selectedForm.survey_name ? vm.selectedForm.survey_name : '',
                    opt_in: vm.selectedForm.opt_in ? vm.selectedForm.opt_in : 0,
                    optin_thankyou_message: vm.selectedForm.optin_thankyou_message ? vm.selectedForm.optin_thankyou_message : '',
                    optin_headline: vm.selectedForm.optin_headline ? vm.selectedForm.optin_headline : '',
                    has_optin_video: vm.selectedForm.has_optin_video ? vm.selectedForm.has_optin_video : 0,
                    optin_video_type: vm.selectedForm.optin_video_type ? vm.selectedForm.optin_video_type : 1,
                    optin_video_url: vm.selectedForm.optin_video_url ? vm.selectedForm.optin_video_url : '',
                    optin_video_embed: vm.selectedForm.optin_video_embed ? vm.selectedForm.optin_video_embed : '',
                    optin_file: vm.selectedForm.optin_file ? vm.selectedForm.optin_file : '',
                    submit_with_member_id: vm.selectedForm.submit_with_member_id ? vm.selectedForm.submit_with_member_id : 0,
                    add_ons: vm.selectedForm.add_ons ? vm.selectedForm.add_ons.split(',') : [],
                    type: vm.selectedForm.type ? vm.selectedForm.type : 'Prospect',
                    is_signature: vm.selectedForm.is_signature ? vm.selectedForm.is_signature : 0,
                    signature: vm.selectedForm.signature ? vm.selectedForm.signature : '',
                    store_copy: vm.selectedForm.store_copy ? vm.selectedForm.store_copy : 'Yes',
                    send_copy: vm.selectedForm.send_copy ? vm.selectedForm.send_copy : 0,
                    has_optin_tagging: vm.selectedForm.has_optin_tagging ? vm.selectedForm.has_optin_tagging : 0,
                    reward_details: vm.selectedForm.reward_details ? JSON.parse(JSON.stringify(vm.selectedForm.reward_details)) : {},
                    respondent_message: vm.selectedForm.respondent_message ? JSON.parse(JSON.stringify(vm.selectedForm.respondent_message)) : {},
                    tags: vm.selectedForm.tags ? JSON.parse(JSON.stringify(vm.selectedForm.tags)) : [],
                    submit_label: vm.selectedForm.submit_label ? JSON.parse(JSON.stringify(vm.selectedForm.submit_label)) : [],
                    notification: vm.selectedForm.notification ? vm.selectedForm.notification : 0,
                    notification_prop: vm.selectedForm.notification_prop ? JSON.parse(JSON.stringify(vm.selectedForm.notification_prop)) : {},
                    company_branding: vm.selectedForm.company_branding ? vm.selectedForm.company_branding : '',
                    display_logo: vm.selectedForm.display_logo ? vm.selectedForm.display_logo : 0,
                    header_bgcolor: vm.selectedForm.header_bgcolor ? vm.selectedForm.header_bgcolor : '#FFF',
                    header_textcolor: vm.selectedForm.header_textcolor ? vm.selectedForm.header_textcolor : '#000',
                    logo: vm.selectedForm.logo ? vm.selectedForm.logo : '',
                    author: vm.selectedForm.author ? vm.selectedForm.author : '',
                    cover_type: vm.selectedForm.cover_type ? vm.selectedForm.cover_type : 2,
                    display_hero: vm.selectedForm.display_hero ? vm.selectedForm.display_hero : 0,
                    other_header_info: vm.selectedForm.other_header_info ? vm.selectedForm.other_header_info : '',
                    description: vm.selectedForm.description ? vm.selectedForm.description : '',
                    cover: vm.selectedForm.cover ? vm.selectedForm.cover : '',
                    has_small_screen_cover: vm.selectedForm.has_small_screen_cover ? vm.selectedForm.has_small_screen_cover : 0,
                    small_cover: vm.selectedForm.small_cover ? vm.selectedForm.small_cover : '',
                    has_cover_overlay: vm.selectedForm.has_cover_overlay ? vm.selectedForm.has_cover_overlay : 0,
                    overlay_color: vm.selectedForm.overlay_color ? vm.selectedForm.overlay_color : '',
                    overlay_opacity: vm.selectedForm.overlay_opacity ? vm.selectedForm.overlay_opacity : '',
                    cover_background_color: vm.selectedForm.cover_background_color ? vm.selectedForm.cover_background_color : '',
                    display_footer: vm.selectedForm.display_footer ? vm.selectedForm.display_footer : 0,
                    has_facebook: vm.selectedForm.has_facebook ? vm.selectedForm.has_facebook : 0,
                    has_twitter: vm.selectedForm.has_twitter ? vm.selectedForm.has_twitter : 0,
                    has_instagram: vm.selectedForm.has_instagram ? vm.selectedForm.has_instagram : 0,
                    has_linkedin: vm.selectedForm.has_linkedin ? vm.selectedForm.has_linkedin : 0,
                    has_youtube: vm.selectedForm.has_youtube ? vm.selectedForm.has_youtube : 0,
                    footer_facebook: vm.selectedForm.footer_facebook ? vm.selectedForm.footer_facebook : '',
                    footer_twitter: vm.selectedForm.footer_twitter ? vm.selectedForm.footer_twitter : '',
                    footer_instagram: vm.selectedForm.footer_instagram ? vm.selectedForm.footer_instagram : '',
                    footer_linkedin: vm.selectedForm.footer_linkedin ? vm.selectedForm.footer_linkedin : '',
                    footer_youtube: vm.selectedForm.footer_youtube ? vm.selectedForm.footer_youtube : '',
                    footer_textcolor: vm.selectedForm.footer_textcolor ? vm.selectedForm.footer_textcolor : '',
                    footer_bgcolor: vm.selectedForm.footer_bgcolor ? vm.selectedForm.footer_bgcolor : '#f5f5f5',
                    footer_company: vm.selectedForm.footer_company ? vm.selectedForm.footer_company : '',
                    footer_terms: vm.selectedForm.footer_terms ? vm.selectedForm.footer_terms : '',
                    footer_policy: vm.selectedForm.footer_policy ? vm.selectedForm.footer_policy : '',
                    thankyou_use_current_cover: vm.selectedForm.thankyou_use_current_cover ? vm.selectedForm.thankyou_use_current_cover : 0,
                    thankyou_cover_image: vm.selectedForm.thankyou_cover_image ? vm.selectedForm.thankyou_cover_image : '',
                    thankyou_cover_small: vm.selectedForm.thankyou_cover_small ? vm.selectedForm.thankyou_cover_small : '',
                    page_type: vm.selectedForm.page_type ? vm.selectedForm.page_type : 1,
                    thankyou_message: vm.selectedForm.thankyou_message ? vm.selectedForm.thankyou_message : '',
                    header: vm.selectedForm.header ? vm.selectedForm.header : '',
                    sub_header: vm.selectedForm.sub_header ? vm.selectedForm.sub_header : '',
                    content: vm.selectedForm.content ? vm.selectedForm.content : '',
                    has_thankyou_video_settings: vm.selectedForm.has_thankyou_video_settings ? parseInt(vm.selectedForm.has_thankyou_video_settings) : 0,
                    video: vm.selectedForm.video ? JSON.parse(JSON.stringify(vm.selectedForm.video)) : {},
                    url: vm.selectedForm.url ? vm.selectedForm.url : '',
                    thankyou_redirect_new_tab: vm.selectedForm.thankyou_redirect_new_tab ? vm.selectedForm.thankyou_redirect_new_tab : 0,
                    fb_pixel: vm.selectedForm.fb_pixel ? vm.selectedForm.fb_pixel : '',
                    fb_pixel_thankyou: vm.selectedForm.fb_pixel_thankyou ? vm.selectedForm.fb_pixel_thankyou : '',
                    seo_title: vm.selectedForm.seo_title ? vm.selectedForm.seo_title : '',
                    seo_keyword: vm.selectedForm.seo_keyword ? vm.selectedForm.seo_keyword : '',
                    seo_desc: vm.selectedForm.seo_desc ? vm.selectedForm.seo_desc : '',
                    seo_index: vm.selectedForm.seo_index ? vm.selectedForm.seo_index : 0,
                    seo_share_title: vm.selectedForm.seo_share_title ? vm.selectedForm.seo_share_title : '',
                    seo_share_desc: vm.selectedForm.seo_share_desc ? vm.selectedForm.seo_share_desc : '',
                    seo_thumb: vm.selectedForm.seo_thumb ? vm.selectedForm.seo_thumb : '',
                    seo_fav: vm.selectedForm.seo_fav ? vm.selectedForm.seo_fav : '',
                    survey_message: vm.selectedForm.survey_message ? JSON.parse(JSON.stringify(vm.selectedForm.survey_message)) : {},
                    delivery_notification_status: vm.selectedForm.delivery_notification_status ? vm.selectedForm.delivery_notification_status : 0,
                    thankyou_bgcolor: vm.selectedForm.thankyou_bgcolor ? vm.selectedForm.thankyou_bgcolor : '#FFF',
                    thankyou_textcolor: vm.selectedForm.thankyou_textcolor ? vm.selectedForm.thankyou_textcolor : '#2c3e50',
                    cover_textcolor: vm.selectedForm.cover_textcolor ? vm.selectedForm.cover_textcolor : '',
                    is_private: vm.selectedForm.is_private ? vm.selectedForm.is_private : 0,
                    // instruction_settings: vm.selectedForm.instruction_settings ? vm.selectedForm.instruction_settings : {},
                    display_instructions: vm.selectedForm.display_instructions ? vm.selectedForm.display_instructions : 0,
                    instruction_settings: vm.selectedForm.instruction_settings ? JSON.parse(JSON.stringify(vm.selectedForm.instruction_settings)) : {},
                    display_question_headline: vm.selectedForm.display_question_headline ? vm.selectedForm.display_question_headline : 0,
                    question_headline: vm.selectedForm.question_headline ? vm.selectedForm.question_headline : '',
                    display_progressbar: vm.selectedForm.display_progressbar ? vm.selectedForm.display_progressbar : 0,
                    progressbar_color: vm.selectedForm.progressbar_color ? vm.selectedForm.progressbar_color : '#2F7FED',
                    display_coach: vm.selectedForm.display_coach ? vm.selectedForm.display_coach : 0,
                    coach_headline: vm.selectedForm.coach_headline ? vm.selectedForm.coach_headline : '',
                    coach_name: vm.selectedForm.coach_name ? vm.selectedForm.coach_name : '',
                    coach_title: vm.selectedForm.coach_title ? vm.selectedForm.coach_title : '',
                    coach_bio: vm.selectedForm.coach_bio ? vm.selectedForm.coach_bio : '',
                    coach_image: vm.selectedForm.coach_image ? vm.selectedForm.coach_image : '',
                    has_thankyou_small_cover: vm.selectedForm.has_thankyou_small_cover ? vm.selectedForm.has_thankyou_small_cover : 0,
                    form_description: vm.selectedForm.form_description ? vm.selectedForm.form_description : '',
                    apply_saved_styling: applySavedStyling,
                };

                vm.form.reward_details.notification_method = parseInt(vm.form.reward_details.notification_method);
                vm.form.reward_details.status              = parseInt(vm.form.reward_details.status ? vm.form.reward_details.status : 0);
                vm.form.respondent_message.option          = parseInt(vm.form.respondent_message.option);
                vm.form.notification_prop.option           = parseInt(vm.form.notification_prop.option);
                vm.form.video.option                       = parseInt(vm.form.video.option);

                vm.respondentEmail = {
                    defaultHandler: true,
                    subject: vm.selectedForm.respondent_message.email_subject,
                    email: vm.selectedForm.respondent_message.email_message,
                    is_email_logo: vm.selectedForm.optin_show_email_logo ? vm.selectedForm.optin_show_email_logo : 0,
                    is_signature: vm.selectedForm.respondent_message.is_signature ? vm.selectedForm.respondent_message.is_signature : 0,
                    signature_id: vm.selectedForm.respondent_message.signature_id ? vm.selectedForm.respondent_message.signature_id : 0,
                    show_email_logo: vm.selectedForm.optin_show_email_logo ? vm.selectedForm.optin_show_email_logo : 0,
                    email_logo: vm.selectedForm.optin_email_logo,
                    module_id: vm.selectedForm.id,
                };

                vm.notificationEmail = {
                    defaultHandler: true,
                    subject: vm.selectedForm.notification_prop.email_subject,
                    email: vm.selectedForm.notification_prop.email_message,
                    is_email_logo: vm.selectedForm.submit_show_email_logo ? vm.selectedForm.submit_show_email_logo : 0,
                    is_signature: vm.selectedForm.notification_prop.is_signature ? vm.selectedForm.notification_prop.is_signature : 0,
                    signature_id: vm.selectedForm.notification_prop.signature_id ? vm.selectedForm.notification_prop.signature_id : 0,
                    show_email_logo: vm.selectedForm.submit_show_email_logo ? vm.selectedForm.submit_show_email_logo : 0,
                    email_logo: vm.selectedForm.submit_email_logo,
                    recipient: vm.selectedForm.notification_prop && vm.selectedForm.notification_prop.email_recipient ? vm.selectedForm.notification_prop.email_recipient : (vm.user.reply_to_email ? vm.user.reply_to_email : vm.user.email),
                    module_id: vm.selectedForm.id,
                };

                vm.deliveryEmail = {
                    defaultHandler: true,
                    defaultEmail: true,
                    subject: vm.selectedForm.survey_message.email_subject,
                    email: vm.selectedForm.survey_message.email_message,
                    email_button_bg_color: vm.selectedForm.survey_message.email_button_bg_color ? vm.selectedForm.survey_message.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.selectedForm.survey_message.email_button_text_color ? vm.selectedForm.survey_message.email_button_text_color : '#FFF',
                    is_email_logo: vm.selectedForm.survey_message.is_page_logo,
                    is_signature: vm.selectedForm.survey_message.is_signature ? vm.selectedForm.survey_message.is_signature : 0,
                    signature_id: vm.selectedForm.survey_message.signature_id ? vm.selectedForm.survey_message.signature_id : 0,
                    show_email_logo: vm.selectedForm.survey_message.is_page_logo,
                    email_logo: vm.selectedForm.survey_message.page_image ? vm.selectedForm.survey_message.page_image : '',
                    module_id: vm.selectedForm.id,
                };

                vm.tagForm = {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                };

                vm.respondentSmsMedia   = vm.form.respondent_message.media ? vm.form.respondent_message.media : [];
                vm.notificationSmsMedia = vm.form.notification_prop.media ? vm.form.notification_prop.media : [];
                vm.deliverySmsMedia     = vm.form.survey_message.media ? vm.form.survey_message.media : [];
                vm.started              = vm.selectedForm.display_instructions ? false : true;

                setTimeout(function () {
                    vm.signatureResetWatcher += 1;
                    vm.contentResetWatcher += 1;
                    vm.resetToDefaultWatcher += 1;
                }, 1000);
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleSubmit(form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.setFieldError = setFieldError;
                params.respondent_message.email_subject       = vm.respondentEmail.subject;
                params.respondent_message.email_message       = vm.respondentEmail.email;
                params.respondent_message.is_signature        = vm.respondentEmail.is_signature;
                params.respondent_message.signature_id        = vm.respondentEmail.signature_id;
                params.respondent_message.media               = vm.respondentSmsMedia;
                params.optin_show_email_logo                  = vm.respondentEmail.show_email_logo;
                params.optin_email_logo                       = vm.respondentEmail.email_logo;
                params.notification_prop.email_subject        = vm.notificationEmail.subject;
                params.notification_prop.email_message        = vm.notificationEmail.email;
                params.notification_prop.email_recipient      = vm.notificationEmail.recipient;
                params.notification_prop.is_signature         = vm.notificationEmail.is_signature;
                params.notification_prop.signature_id         = vm.notificationEmail.signature_id;
                params.notification_prop.media                = vm.notificationSmsMedia;
                params.submit_show_email_logo                 = vm.notificationEmail.show_email_logo;
                params.submit_email_logo                      = vm.notificationEmail.email_logo;
                params.survey_message.email_subject           = vm.deliveryEmail.subject;
                params.survey_message.email_message           = vm.deliveryEmail.email;
                params.survey_message.is_signature            = vm.deliveryEmail.is_signature;
                params.survey_message.signature_id            = vm.deliveryEmail.signature_id;
                params.survey_message.is_page_logo            = vm.deliveryEmail.show_email_logo;
                params.survey_message.page_image              = vm.deliveryEmail.email_logo;
                params.survey_message.media                   = vm.deliverySmsMedia;
                params.survey_message.email_button_bg_color   = vm.deliveryEmail.email_button_bg_color;
                params.survey_message.email_button_text_color = vm.deliveryEmail.email_button_text_color;

                if (vm.step == 1 && vm.$refs[`question-form-${vm.questionIndex}`] && vm.$refs[`question-form-${vm.questionIndex}`].handleSubmitQuestion) {
                    vm.$refs[`question-form-${vm.questionIndex}`].handleSubmitQuestion();
                }

                vm.updateForm(params).then((result) => {
                    if (result) {
                        if (vm.step < 5) {
                            vm.step++;
                        } else {
                            vm.$router.push({ name: 'FormIndex' })
                        }
                    }
                });
            },

            handleRespondentDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}},</p>';
                    email += '<p>Below is a copy of the form you recently submitted through {{company_name}}.</p>';
                    email += '<p>Use the link to see your responses and download a copy for yourself.</p>';
                    email += '<p>{{form_url}}</p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>{{company_owner}}</p>';
                    email += '<p>{{company_name}}</p>';

                vm.respondentEmail.subject  = 'Your copy {{first_name}}';
                vm.respondentEmail.email    = email;
            },

            handleRespondentDefaultSms () {
                const vm = this;

                vm.form.respondent_message.sms_message  = 'Hi {{first_name}},\r\nBelow is a copy of the form you recently submitted through {{company_name}}.\r\nUse the link to see your responses and download a copy for yourself.\r\n{{form_url_sms}}\r\nCheers,\r\n{{company_owner}}\r\n{{company_name}}';
            },

            handleNotificationDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{company_owner}}</p>';
                    email += '<p>Someone just submitted answers to {{form_name}} form!</p>';
                    email += '<p>Click the link below to view them now.</p>';
                    email += '<p>{{form_url}}</p>';

                vm.notificationEmail.subject  = 'Survey Notification!';
                vm.notificationEmail.email    = email;
            },

            handleNotificationDefaultSms () {
                const vm = this;

                vm.form.notification_prop.sms_message  = 'Hi {{company_owner}},\r\nSomeone just submitted answers to {{form_name}} form! Click the link to view them now.\r\n{{form_url_sms}}';
            },

            handleNotificationSmsChange (object) {
                const vm = this;

                vm.form.notification_prop.sms_recipient = object.number;
            },

            goTo (refName, delay = 100) {
                const vm = this;

                setTimeout(function () {
                    let element = '';

                    if (refName == 'signature-required-section') {
                        element = vm.$refs['form-content'].$refs[refName]
                    } else if (refName == 'submitbutton') {
                        element = vm.$refs['form-content'].$refs[refName]
                    } else {
                        element = vm.$refs[refName];
                    }

                    let top = element.offsetTop;

                    setTimeout(function() {
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }, delay);
                }, delay);
            },

            setting (refName, tab = null) {
                const vm = this;

                setTimeout(function () {
                    let element = vm.$refs[refName];
                    let top = element.offsetTop;

                    setTimeout(function () {
                        document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                    }, 100);
                }, 10);
            },

            handleDeliveryDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}},</p>';
                    email += '<p>{{owner_first_name}} from {{company_name}} is requesting that you complete a form.</p>';
                    email += '<p>{{form_url_button}}</p>';
                    email += '<p><br />Sincerely</p>';
                    email += '<p>Team {{company_name}}</p>';

                vm.deliveryEmail.subject  = 'A form is due';
                vm.deliveryEmail.email    = email;
            },

            handleDeliveryDefaultSms () {
                const vm = this;

                vm.form.survey_message.sms_message  = 'Hi {{first_name}},\r\n{{owner_first_name}} from {{company_name}} is requesting that you complete a form.\r\n{{form_url_sms}}\r\nSincerely\r\nTeam {{company_name}}';
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            updateSmsText () {
                const vm = this;

                const notifSms   = vm.$refs['notif-sms-component'];
                const surveySms  = vm.$refs['survey-sms-component'];

                if (notifSms) {
                    vm.notificationSmsText = notifSms.smsMedia && notifSms.smsMedia.media && notifSms.smsMedia.media[0] ? vm.form.notification_prop.sms_message + '\n\n' + notifSms.smsMedia.media[0].toString() : vm.form.notification_prop.sms_message;
                }

                if (surveySms) {
                    vm.surveySmsText = surveySms.smsMedia && surveySms.smsMedia.media && surveySms.smsMedia.media[0] ? vm.form.survey_message.sms_message + '\n\n' + surveySms.smsMedia.media[0].toString() : vm.form.survey_message.sms_message;
                }
            },

            toggleSectionBar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            toggleQuestion (ev, index) {
                const vm = this;

                if (vm.$refs[`question-form-${vm.questionIndex}`] && vm.$refs[`question-form-${vm.questionIndex}`].handleSubmitQuestion) {
                    vm.$refs[`question-form-${vm.questionIndex}`].handleSubmitQuestion();
                }

                vm.questionIndex = vm.questionIndex != index ? index : -1;
            },

            addNewQuestion () {
                const vm = this;

                vm.questions.push({
                    headline: '',
                    question: '',
                    sub_text: '',
                    answer_required_yn: 0,
                    is_initial: 0,
                    input_type_id: 1,
                    options: [],
                });

                vm.questionIndex = vm.questions.length - 1;

                setTimeout(function () {
                    let allParentElement = document.querySelectorAll('.faq_list li');

                    for (let i = 0; i < allParentElement.length; i++) {
                        allParentElement[i].classList.remove('open');
                    }

                    const accordion = document.getElementById(`question-accordion-${(vm.questions.length - 1)}`);
                    accordion.classList.add('open');
                    accordion.scrollIntoView();
                }, 10);
            },

            handleQuestionSort () {
                const vm = this;
                const ids = vm.questions.map((question) => { return question.id });

                vm.sortQuestions({ ids });
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            handleDeleteQuestion (question) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this question', 'Delete');
                      option.preConfirm = () => vm.deleteQuestion({ form_id: vm.selectedForm.id, question_id: question.id });


                Swal.fire(option);
            },

            handleApplySavedStyle (section = 'footer') {
                const vm = this;

                if (vm.formDefaultContent) {
                    if (section == 'footer') {
                        vm.form.display_footer   = vm.formDefaultContent.display_footer ? 1 : 0;
                        vm.form.footer_company   = vm.formDefaultContent.footer_company;
                        vm.form.footer_bgcolor   = vm.formDefaultContent.footer_bgcolor;
                        vm.form.footer_policy    = vm.formDefaultContent.footer_policy;
                        vm.form.footer_terms     = vm.formDefaultContent.footer_terms;
                        vm.form.footer_textcolor = vm.formDefaultContent.footer_textcolor;
                        vm.form.footer_facebook  = vm.formDefaultContent.footer_facebook;
                        vm.form.footer_twitter   = vm.formDefaultContent.footer_twitter;
                        vm.form.footer_instagram = vm.formDefaultContent.footer_instagram;
                        vm.form.footer_linkedin  = vm.formDefaultContent.footer_linkedin;
                        vm.form.footer_youtube   = vm.formDefaultContent.footer_youtube;
                        vm.form.has_instagram    = vm.formDefaultContent.has_footer_instagram ? 1 : 0;
                        vm.form.has_facebook     = vm.formDefaultContent.has_footer_facebook ? 1 : 0;
                        vm.form.has_twitter      = vm.formDefaultContent.has_footer_twitter ? 1 : 0;
                        vm.form.has_linkedin     = vm.formDefaultContent.has_footer_linkedin ? 1 : 0;
                        vm.form.has_youtube      = vm.formDefaultContent.has_footer_youtube ? 1 : 0;
                    }
                }
            },

            handleFormInvalidSubmitV2 (form) {
                const vm = this;

                if (form && Object.keys(form.errors).length) {
                    Toastr.error(Object.values(form.errors)[0]);
                }

                const elements = document.getElementsByClassName('has-error');

                if (elements.length) {
                    if (elements[0].closest('.content_wpr')) {
                        const step = elements[0].closest('.content_wpr').getAttribute('data-step');

                        if (step >= 0) {
                            vm.step = step;
                        }
                    }

                    elements[0].closest('.content_wpr').style.display = 'block';
                    elements[0].scrollIntoView(true);
                }
            },

            openSlide () {
                const vm = this;

                vm.instructorDetails = true;
            },

            handleApplySavedStylingWarning () {
                const vm = this;

                if (vm.form.apply_saved_styling) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'Enabling this will update your header, cover, footer, and more with saved styles, and this action cannot be undone.');

                    Swal.fire(options).then((result) => {
                        if (result.isDismissed) {
                            vm.form.apply_saved_styling = 0;
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .optin_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .optin_list li {
        flex: 0 0 33.333%;
        padding: 5px;
        display: flex;
    }
    .optin_list li .field_box{
        width: 100%;
        background: #f9f9f9;
        padding: 12px 15px;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
    }
    .optin_list li label {
        cursor: pointer;
    }
    .optin_list li label.checkbox span{
        background: #fff;
    }
    .optin_list li label.checkbox p{
        padding-left: 10px;
    }

    /* Preview area */
    .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .dashboard_content {
        flex-wrap: inherit;
        align-items: stretch;
        flex-direction: column;
        height: calc(760px - 150px);
    }

    .dashboard_content .left_side{
        padding: 0 15px 30px 15px;
        width: 100%;
    }
    .dashboard_content .right_side {
        padding: 15px 15px 30px 15px;
        width: 100%;
        display: none;
    }

    .cell .dashboard_content h3 {
        font-size: 14px;
        line-height: 19px;
        padding-top: 0 !important;
        margin-bottom: 15px;
    }

    .border-item {
        padding: 15px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .dashboard_content .para {
        font-size: 11px;
        line-height: 15px;
        color: #585858;
        font-weight: 400;
    }

    .coach_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 10px 15px;
        background: #fff;
        display: none;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .coach_card .user_box img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    :deep(.coach_card p) {
        font-size: 11px !important;
        line-height: 17px !important;
        font-weight: 400;
        color: #717171;
        display: block;
        margin: 10px 0;
        font-family: 'Inter', sans-serif;
    }

    .preview_content .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
        max-width: 100%;
    }

    .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: #2f7fed;
        transition: all 0.3s ease-in-out;
    }

    .client_card {
        width: 100%;
        border: 0;
    }

    .client_card .card_header {
        padding: 15px 20px;
    }

    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }

    .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }

    .client_card .card_body {
        height: auto;
        text-align: center;
        /* border-radius: 0 0 5px 5px; */
    }

    .client_card .card_body .banner_img {
        position: relative;
    }

    .client_card .card_body h1 {
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .client_card .card_body h4 {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .client_card .card_body h2 {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .client_card .card_body h6 {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #fff;
        /* text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
        position: relative;
        z-index: 3;
    }

    .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
        /* margin-top: 30px; */
    }

    .dashboard_footer ul li {
        padding: 2px;
    }

    .dashboard_footer ul li img {
        width: 20px;
        height: 20px;
    }

    .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .global_setting .preview_content p {
        font-size: 9px;
        line-height: 11px;
    }

    .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }
    /* :deep(.dashboard_content .form_grp.mt-2){
        margin-top: 0 !important;
    }
    :deep(.dashboard_content .faq_list h5), :deep(.dashboard_content .form_grp .input_label) {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
    } */

    .dashboard_content .action_wpr .btn {
        font-size: 10px;
        line-height: 25px;
        padding: 0 15px;
        min-width: 70px;
        height: 25px;
    }

    :deep(.cell .dashboard_content .primary_btn){
        font-size: 16px;
        line-height: 20px;
        padding: 0 15px;
        height: 55px;
        letter-spacing: 1px;
        margin-top: 15px;
    }

    /* :deep(.dashboard_content .field_wpr input) {
        height: 25px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
    } */

    .dashboard_content .field_wpr textarea {
        height: 50px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
    }

    /* .privacy_info {
        display: flex;
        padding: 15px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 20px 0;
    }

    .privacy_info i {
        font-size: 25px;
        color: var(--var-accent-color);
    }

    .privacy_info p {
        font-size: 10px;
        line-height: 13px;
        margin-left: 7px;
    } */

    .signature_fld {
        display: flex;
        justify-content: space-between;
        font-family: 'Caveat', cursive;
        margin: 0 0 10px 0;
        padding: 5px 0;
        font-size: 14px;
        line-height: 17px;
        border-bottom: 1px solid #d9d9d9;
    }

    .dashboard_content .edit_section {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
        position: relative;
        border-radius: 6px;
        background: #fff;
    }

    .dashboard_content .edit_section:before {
        /* background: #f2a31d; */
        background: var(--var-accent-color);
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 3px;
        border-radius: 6px 6px 0 0;
    }

    .thank_content {
        /* max-width: 320px; */
        margin: 50px 15px;
    }

    .feedback {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
    }
    .feedback .video_wpr :deep(iframe){
        border-radius: 0;
    }
    .feedback .redactor-styles.redactor-in.thankyou_content {
        padding: 20px;
        margin-bottom: 0;
        font-size: 13px !important;
        line-height: 20px;
        font-weight: 400;
    }
    .feedback .redactor-styles.redactor-in :deep(p){
        margin-bottom: 0;
        font-size: 13px !important;
        line-height: 20px;
        font-weight: 400;
    }

    .alignment_area {
        display: flex;
        justify-content: space-between;
        margin: 0 -10px;
    }

    .alignment_area li {
        padding: 5px 10px;
        width: 33.333%;
    }

    .alignment_area li label {
        border: 1px solid #E9E9E9;
        display: block;
        padding: 15px 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        cursor: pointer;
    }

    .alignment_area li label h5 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #2f7fed;
        margin-top: 10px;
        filter: grayscale(1);
    }

    .alignment_area li img {
        height: 70px;
        width: auto;
        filter: grayscale(1);
    }

    .alignment_area li label input:checked~img,
    .alignment_area li label input:checked~h5 {
        filter: grayscale(0);
    }

    .preview_content .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after,
    .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    .preview_content .dashboard_footer ul li {
        padding: 2px;
    }

    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }

    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }

    .google_ranking {
        background: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 30px;
        margin: 12px 0;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
    }

    .google_ranking h6 {
        font-size: 12px;
        line-height: 14px;
        color: #70757a;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .google_ranking h4 {
        font-size: 15px;
        line-height: 20px;
        color: #1a0dab;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .google_ranking P {
        font-size: 12px;
        line-height: 17px;
        color: #4d5156;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .seo_card {
        max-width: 450px;
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        overflow: hidden;
        line-height: 0;
        margin: 12px 0;
    }

    .seo_card img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .seo_card .seo_info {
        padding: 25px 30px;
        background: #fff;
    }

    .seo_card .seo_info h3 {
        font-size: 17px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .seo_card .seo_info p {
        font-size: 12px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .browser_layout {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 5px 0 2px 0;
        max-width: 450px;
        margin: 12px 0;
    }

    .browser_tabs {
        display: flex;
        padding: 0 10px;
    }

    .browser_tabs li {
        padding: 7px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        background: #fff;
        flex: 0 0 150px;
        position: relative;
    }

    .browser_tabs li:before {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 0 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        left: -8px;
    }

    .browser_tabs li:after {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 8px 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        right: -8px;
    }

    .browser_tabs li h6 {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .browser_tabs li i {
        margin-left: auto;
        font-size: 10px;
        color: #5a5a5a;
    }

    .browser_tabs li img {
        max-width: 15px;
        height: auto;
        margin-right: 7px;
    }

    .browser_tabs li:last-child {
        flex: 0 0 auto;
        background: transparent;
        padding: 10px;
        position: static;
    }

    .browser_tabs li:last-child i {
        color: #121525;
    }

    .browser_info {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        margin: 0 2px;
        border-radius: 3px;
    }

    .browser_info i {
        font-size: 10px;
        margin: 0 5px;
    }

    .browser_info img.user {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .browser_search {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 6px;
        border-radius: 12px;
        margin: 0 10px;
        flex: 1;
    }

    .browser_search p,
    .browser_search i {
        font-size: 9px;
        line-height: 11px;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }
    .cell .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .cell .msg_preview:after, .cell .sms_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .cell .sms_preview:after{
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview :deep(p) {
        font-size: 11px !important;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter :deep(p){
        font-size: 11px;
        line-height: 17px;
    }
    .cell .msgFooter :deep(p a){
        text-decoration: none;
    }

    .content_area.neg_margin {
        margin: 0 -50px;
        padding: 0;
    }

    .area_wpr {
        border-bottom: 1px solid #eeeeee;
        padding: 20px 50px 40px;
    }

    .area_wpr:last-of-type {
        border: 0;
    }

    /* .field_wpr.has_prefix {
        padding-left: 40px;
    }

    .field_wpr.has_prefix .prefix {
        widows: 40px;
    }

    .field_wpr.has_prefix .prefix i {
        font-size: 15px;
    } */

    .banner_content {
        position: absolute;
        top: 50%;
        left: 0;
        /* transform: translateY(-50%); */
        z-index: 2;
        width: 100%;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    /* .sender_info .info p {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #999;
    } */

    iframe.thankyou-redirect {
        width: 100%;
        height: 100vh;
    }
    .alt_prev{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .alt_prev li{
        font-size: 13px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 12px;
        border-right: 1px solid #d9d9d9;
        cursor: pointer;
    }
    .alt_prev li:last-child{
        border: 0;
    }
    .alt_prev li.active{
        color: #2f7eed;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 2px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        /* background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px; */
        max-width: 500px;
        margin: 0 auto;
        position: relative;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        /* flex: 1 1 20%; */
    }
    /* .step_bar2 ul li:first-child{
        align-items: flex-start;
        flex: 0 1 13.4%;
    }
    .step_bar2 ul li:last-child{
        align-items: flex-end;
        flex: 0 1 13.4%;
    } */

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    /* .sm_switch{
        display: flex;
        justify-content: flex-start;
        margin-right: -8px;
    }
    .sm_switch .switch_option{
        transform: scale(0.8);
    } */
    .quest_headline{
        padding: 0 30px 15px 30px;
        border-radius: 6px;
        margin: 5px 0 20px;
        background: #fff;
        /* border: 1px solid #e9e9e9; */
    }
    .faq_list li {
        /* border: 1px solid #eaeaea; */
        /* background: #fafafb; */
        border-radius: 6px;
        margin-bottom: 25px;
        /* padding: 20px; */
    }
    .faq_list li h5.category_bar {
        font-size: 13px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: flex-start;
        background: #fff;
        border-radius: 6px;
        padding: 0 15px;
        /* column-gap: 15px; */
    }
    .faq_list li.open h5.category_bar{
        border-radius: 6px 6px 0 0;
    }
    .faq_list li h5 .quest_box {
        padding: 15px 0;
        flex: 1 1 auto;
    }
    .faq_list li h5 .quest_box :deep(.redactor-styles){
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .faq_list li h5 .quest_box :deep(.redactor-styles p){
        margin: 0;
    }
    .faq_list li h5 .quest_box :deep(.redactor-styles), .faq_list li h5 .quest_box :deep(.redactor-styles p){
        font-size: 15px;
        line-height: 22px;
    }
    .faq_list li h5 .sub_text {
        padding: 7px 0 0 0;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .faq_list li h5 .edit {
        margin: 0 15px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        transition: all 0.3s ease-in-out;
    }
    .faq_list li h5 label {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }
    .faq_list .delete_btn{
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }
    .category_bar .bar_text{
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        position: relative;
    }
    .category_bar .bar_text .handle-cat-drag{
        margin-top: 25px;
    }

    .category_bar .bar_actions{
        display: flex;
        align-items: center;
        height: 50px;
        margin: 6px 0 6px 10px;
    }
    .faq_list li .quest_setup{
        padding: 10px 30px 0 30px;
        border-top: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 0 0 6px 6px;
        display: none;
        cursor: auto;
    }
    .faq_list li.open .quest_setup{
        display: block;
    }
    .faq_list li.open h5 .edit{
        transform: rotate(180deg);
    }
    .quest_setup :deep(form > .action_wpr){
        margin: 0 -30px;
        padding: 20px 30px;
    }
    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }
    .progress_sec {
        margin-bottom: 40px;
    }
    .progress_sec h3{
        font-size: 17px !important;
        line-height: 22px !important;
    }
    .video_wpr{
        position: relative;
        padding-bottom: 56.25%;
        line-height: 0;
    }
    .video_wpr :deep(iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    img.start_screen_placeholder{
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }

    /* toggle_section */
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .section_content .section_header .switch_option{
        flex: 1 1 auto;
    }
    .section_header h2 span{
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }
    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }
    .sub_header h6 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        padding: 3px 0;
    }
    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }
    .cell .mobile_menu{
        display: flex;
        padding: 0 0 20px 0;
    }
    .slide_box_container{
        height: 610px;
        position: absolute;
        top: 60px;
        bottom: 80px;
        left: 15px;
        right: 15px;
        overflow: hidden;
        z-index: 3;
    }
    .slide_box {
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -100%;
        max-height: 430px;
        transition: all .3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0,0,0,.15);
        text-align: left;
        z-index: 3;
        overflow-y: auto;
    }
    .slide_box::-webkit-scrollbar{
        width: 4px;
    }
    .slide_box::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .slide_box.active{
        bottom: 1px;
    }
    .slide_box .close_btn{
        position: sticky;
        float: right;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: rgba(50,55,59,.6);
        color: #fff;
        cursor: pointer;
    }
    .slide_box .instructor{
        padding-bottom: 15px;
    }
    .slide_box .instructor :deep(p){
        font-size: 15px;
        line-height: 27px;
        font-weight: 300;
        color: #0E101A;
    }
    .grey_box{
        background: #f9f9f9;
        padding: 15px 20px;
        border-radius: 5px;
    }
    .color_container .grey_box :deep(.upload_wpr .upload_area){
        background: #fff;
    }
    @media(max-width: 650px){
        .optin_list li{
            flex: 0 0 50%;
        }
    }
    @media(max-width: 1199px){
        .quest_setup :deep(form > .action_wpr){
            padding: 20px 30px !important;
        }
        .quest_setup :deep(form > .action_wpr .btn){
            height: 35px;
            min-width: auto;
            font-size: 11px;
            padding: 0 10px;
        }
        .quest_setup :deep(form > .action_wpr .danger_btn){
            height: 35px;
            width:35px;
            font-size: 11px;
            padding: 0 10px;
        }
        .cover_type{
            margin: 15px -25px;
        }
        .global_setting .tabs_content .section_content:after{
            left: 10px;
            right: 10px;
        }
    }
    @media(max-width: 991px){
        .faq_list li .quest_setup{
            padding: 20px 20px 0 20px;
        }
        .quest_setup :deep(form > .action_wpr){
            margin: 0 -20px;
            padding: 20px !important;
        }
    }
    @media(max-width: 767px){
        .faq_list li h5 .quest_box :deep(.redactor-styles), .faq_list li h5 .quest_box :deep(.redactor-styles p){
            font-size: 13px !important;
            line-height: 20px !important;
        }
    }
    @media(max-width: 499px){
        .faq_list li{
            padding: 15px;
        }
        .faq_list li .quest_setup{
            padding: 15px 15px 0 15px;
        }
        .quest_setup :deep(form > .action_wpr){
            margin: 0 -15px;
            padding: 15px !important;
        }
        .quest_setup :deep(form > .action_wpr .btn){
            height: 30px;
            min-width: auto;
            font-size: 9px;
        }
        .quest_setup :deep(form > .action_wpr .danger_btn){
            height: 30px;
            width:30px;
            font-size: 9px;
        }
        .cover_type{
            margin: 15px -15px;
        }
        .global_setting .tabs_content .section_content:after{
            left: 0;
            right: 0;
        }
        .toggle_section{
            margin-left: 10px;
        }
    }
    @media(max-width: 450px){
        .optin_list li label.checkbox p{
            font-size: 11px;
        }
        .alignment_area{
            flex-wrap: wrap;
            gap: 14px;
            margin: 0;
        }
        .alignment_area li{
            width: calc(50% - 7px);
            padding: 0;
        }
    }
</style>
